.ourplan-main{
    display: flex;
  align-items: center;
  justify-content: center;
  height: 556px;
  max-width: 100%;
  /* border-radius: 2rem; */
  background: url(./strategy-Icons/OurPlan.png) no-repeat; 
  margin: 0rem 3.5rem;
  background-size:100% 100%;
}

.Testing_head{
    /* height: 150.97px; */
    width: 100%;
    background: url(./strategy-Icons/testing-background.svg)no-repeat ;
    background-size:100%;
    margin-bottom: 40.47px;
}
.Scaling_head{
    /* height: 150.97px; */
    width: 100%;
    background: url(./strategy-Icons/scaling-background.svg)no-repeat ;
    background-size:100%;
    margin-bottom: 40.47px;
}

.Scale_head{
    /* height: 150.97px; */
    width: 100%;
    background: url(./strategy-Icons/blended-background.svg)no-repeat ;
    background: #F5F9FF;
    background-size:100%;
    margin-bottom: 40.47px;
}
.ROAS_head{
    /* height: 150.97px; */
    width: 100%;
    background: url(./strategy-Icons/blended-background.svg)no-repeat ;
    background: #F5F9FF;
    background-size:100%;
    margin-bottom: 40.47px;
}