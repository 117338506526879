.manual-selection-container {
  display: flex;
  align-items: center;
  padding: 16px 0 10px 16px;
  border-bottom: 1px solid #eaeaec;
}
.ai-selection-container {
  padding: 14px 0 10px 16px;
  .radio-selection {
    display: flex;
    align-items: center;
  }
  .ai-form-fields {
    background: #f8f9fd;
    border: 1px solid #ebf3ff;
    width: 649px;
    // height: 286px;
    box-shadow: 12px 12px 48px rgba(80, 80, 159, 0.04);
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 8px;
    padding: 11px 11px 10px 11px;
    .form-types {
      display: flex;
      width: 100%;
      background-color: #fff;
      padding: 8px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 16px;
      border-radius: 8px;
      justify-content: space-between;
      // gap: 10px;
    }
  }
}
