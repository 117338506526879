.google_bk{
    display: flex;
    
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: url(../../../../../assets/images/Analysis/google.svg);
    width: 100%;
    height: 150px;
    border-radius: 2px 2px 0px 0px;
    margin-bottom: 37px;
}
.text-field textarea{
font-size: 22px;
font-family: 'Lato';
font-weight: 400;
    line-height: 30.8px;

}

.text-field textarea::placeholder{
    font-size: 22px;
    font-weight: 400;
    line-height: 30.8px;
    color: #333333;
    
}