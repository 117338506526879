.competitor_bk{
    display: flex;
    
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: url(../../../../../assets/images/Analysis/competitor_bg.svg);
    width: 100%;
    height: 150px;
    border-radius: 2px 2px 0px 0px;
    margin-bottom: 37px;
}