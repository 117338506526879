.pmax-strategy-deck {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  gap: 17px;
  background: #ffffff;
  .p-max-detailed-view-container {
    .pmax-detailed-view {
      border: 2px solid #e6f6ff;
      border-radius: 8px;
      padding-top: 7px;
      // padding: 7px 15px 0 15px;
      .pmax-navigation-controls {
        display: flex;
        margin-bottom: 16px;
        padding: 0px 15px 0 15px;
        gap: 12px;

        .pmax-control {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.5);
          // opacity: 0.5;
          padding: 8px 12px;
          border-bottom: 1px solid #f0f0f0;
        }
      }
      .pmax-theme-search {
        display: flex;
        align-items: center;
        gap: 16px;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 2px solid #e6f6ff;
        padding-left: 20px;
        .search-button-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 8px;
          .search-button {
            width: 116px;
            height: 40px;
            background: rgba(8, 105, 251, 0.07);
            border-radius: 8px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #0869fb;
            text-transform: capitalize;
          }
        }
        .MuiOutlinedInput-root {
          border-radius: 8px;
        }
      }
      .pmax-theme-list {
        padding-left: 25px;
        min-height: 316px;
        max-height: 316px;
        overflow-y: scroll;
        margin-bottom: 15px;
      }
      .headline-container {
        padding-left: 16px;
        padding-right: 16px;
        min-height: 380px;
        max-height: 380px;
        overflow-y: scroll;
        margin-top: 24px;
        margin-bottom: 16px;
        .headline {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding-bottom: 12;
          gap: 16px;
          margin-bottom: 16px;
          .headline-text {
            background: #ffffff;
            border: 1px solid #f0f0f0;
            box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.04);
            border-radius: 5px;
            width: 98%;
            padding-left: 15px;

            padding-top: 4px;
            padding-bottom: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 31px;
          }
        }
      }
      .description-container {
        padding-left: 16px;
        padding-right: 16px;
        min-height: 380px;
        max-height: 380px;
        overflow-y: scroll;
        margin-top: 24px;
        margin-bottom: 16px;
        .description {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding-bottom: 12;
          gap: 16px;
          margin-bottom: 16px;
          .description-text {
            background: #ffffff;
            border: 1px solid #f0f0f0;
            box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.04);
            border-radius: 5px;
            width: 98%;
            height: 68px;
            padding-left: 15px;
            padding-top: 4px;
            padding-bottom: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 31px;
          }
        }
      }
      .image-view-container {
        padding: 6px 27px 18px 27px;
        position: relative;
        z-index: 1;
        .image-list-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 28px;
          min-height: 346px;
          max-height: 346px;
          overflow-y: scroll;
          overflow-x: hidden;
          padding: 0.5rem;
          .image {
            width: 162px;
            height: 171px;
            margin-bottom: 32px;
            position: relative;

            .delete-container {
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #fff3f3;
              border-radius: 50%;
              position: absolute;
              top: -8px;
              right: -8px;
              z-index: 100;

              img {
                width: 14px;
                height: 14px;
              }
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .image-resolution-message {
          display: flex;
          gap: 16px;
          padding-bottom: 18px;
          // align-items: center;
        }
      }
    }
  }
}
