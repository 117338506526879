.hero12 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 556px;
  max-width: 100%;
  /* border-radius: 2rem; */
  background: url(../../../assets/icons/onBoardHDMAM.svg) no-repeat;
  margin: 0rem 3.5rem;
  background-size: 100% 100%;
}

.phase {
  display: block;
  align-items: center;
  justify-content: center;
  height: 556px;
  max-width: 100%;
  /* border-radius: 2rem; */
  background: url(../../../assets/icons/onBoardPhaseHDMAM.svg) no-repeat;
  margin: 0rem 3.5rem;
  background-size: 100% 100%;
}
.onboarding-doc__before-started {
  display: flex;
  flex-direction: column;
  gap: 192px;
}
.onboarding-doc_duration,
.onboarding-doc_steps {
  display: flex;
  justify-content: space-between;
  margin: 0 54px;
}
.onboarding-doc_duration {
  z-index: 2;
}
.onboarding-doc_steps {
  justify-content: space-between;
}
.onboarding-doc_duration div {
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  border: 1px solid #6ba5fd;
  background: #f5f9ff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}
.onboarding-doc_steps div {
  width: 160px;
  height: 152px;
  padding: 60px 21px 60px 21px;
  border-radius: 0px 100px 100px 100px;
  background-color: #e9f1ff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -28px;
  /* margin-right: 32px; */
}
.agenda_head {
  height: 114px;
  width: 100%;
  background: url(../../../assets/images/Onboarding/agenda.svg), #f5f9ff;
  background-repeat: no-repeat;
  background-position: right -16px;
  /* position: relative;
  z-index: 10; */
  margin-bottom: 40.47px;
}

.asset-for-you-head {
  height: 114px;
  width: 100%;
  background: url(../../../assets/images/Onboarding/assets-for-you-bg.svg),
    #f5f9ff;
  background-repeat: no-repeat;
  background-position: right 0px;
  background-position-x: 92%;
  /* position: relative;
  z-index: 10; */
  margin-bottom: 40.47px;
}
.list {
  display: flex;
  align-items: center;
  border-radius: 200px;
  height: 72px;
  width: 326px;
  padding: 10px;
  background-color: #f5f9ff;
  margin-left: 24px;
  margin-bottom: 66px;
}

.circle {
  width: 56px;
  margin-right: 16px;
  line-height: 56px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  background-color: #0869fb;
  border: 1px solid transparent;
}

.asset-head {
  height: 114px;
  width: 100%;
  background: url(../../../assets/images/Onboarding/asset.svg);
  background-repeat: no-repeat;
  background-position: right -16px;
  margin-bottom: 40.47px;
}

.connections {
  /* height: 50vh; */
  /* padding-left: 32px;
    padding-right: 32px; */
}

.main_grid {
  display: flex;
  justify-content: space-between;
}
/* .grid_acc_col{
    background: #fff; border-radius: 8px;  padding: 0!important; min-height:364px; width: 100%;
}
.grid_col{
    width: calc(50% - 8px); padding: 0;background: #FFFFFF;margin: 16px;
    box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
    border-radius: 8px; margin-bottom: 16px; display: flex; align-items: center; width: 100%; position: relative; justify-content: space-between;
}

.social_list{
    display: flex; align-items: center;padding: 20px;
} */
.grid_col {
  width: calc(50% - 8px);
  padding: 0;
  background: #ffffff;
  margin: 16px;
  box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}
.grid_acc_col {
  background: #fff;
  border-radius: 8px;
  padding: 0 !important;
  min-height: 364px;
  width: 100%;
}
.social_list {
  display: flex;
  align-items: center;
  padding: 20px;
}
.css-1ex1afd-MuiTableCell-root {
  color: #333333;
}
.strategy {
  margin-bottom: 63px;
}
