.box{
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    padding: 3rem;
    /* height: 100%; */
    /* overflow: ; */
}
.heading{
    font-family: sans-serif;
    color: #7F7D87;
   
   
}
.text{
   width: 400px;
}
.footer_row{border-top:1px solid #DBDEE2; padding: 18px 25px}
.footer_con{display: flex; justify-content: space-between}
.back{ 
    background: none;
    border: none;
    color: #0869FB;
    
    
}
.back:hover{
    cursor: pointer;
}