.processtime {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  margin-top: 126px;
}
