.newcampaignpreview-container{
    max-width: 100%;
    margin-top: 1.9rem;
}
.newcampaignpreview-header{
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.newcampaignpreview-header-title{
    font-family: 'Inter' !important;
    font-size: 25px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #170F49;
    text-align: center;
    text-transform: uppercase;

}
.newcampaignpreview-header-description{
    font-family: 'Inter' !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    text-align: center;
    color: #6F6C90;
   
}
.fb_accordian{
    padding-left: 25px;
    padding-right: 23.92px;
    border-radius: 10px;
    margin-top: 53px;
}
.fb_accordian .campaign_head{
    font-family: 'Inter' !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 19.36px;
    display: flex;
    align-items: center;
    margin-left: 16px;
}

/* brand component */

.leftline{
    width: 20px;
  background-color: #1abfa11a;
  border-top-left-radius: 6.80095px;
  border-bottom-left-radius: 6.80095px;
}
.brand_container{
    height: 120px;
    width: 433px;
    border: 1px solid #F4F4F4;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    padding-left: 17.73px;
    padding-top: 13px;
}
.new_brand_heading{
    font-family: 'Inter' !important;
    
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 28px !important;
    margin-bottom: 12px !important;
}
.new_brand_title{
font-family: 'Inter' !important;

font-weight: 500 !important;
font-size: 12px !important;
line-height: 20px !important;
color: #6F767E;
margin-right: 3px !important;
margin-bottom: 5px !important;
}

.new_brand_subtitle{
    font-family: 'Inter' !important;

font-weight: 500 !important;
font-size: 12px !important;
line-height: 20px !important;
color: #0869FB;
}

.brand_comp{
    display: flex;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
    gap: 20px;
}

/* google */
.google_accordian{
    padding-left: 25px;
    padding-right: 23.92px;
    border-radius: 10px;
    margin-top: 53px;
}

.google_accordian .campaign_head{
    font-family: 'Inter' !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 19.36px;
    display: flex;
    align-items: center;
    margin-left: 16px;
}
