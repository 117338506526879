.input_field {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 12px;
  margin-right: 8px;
  height: 32px;
}
.input_field::placeholder {
  color: #999999;
}
