.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
}
.steps {
  padding: 3rem 0;
  max-height: 590px;
  overflow: auto;
}
.accountManger .steps {
  max-height: 710px;
}
.steps_inner {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-width: 184px;
}

.amlayout .steps_inner {
  max-width: 230px;
}
.stepHelper {
  width: 100%;
  position: relative;
}
.step {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  /* font-family: ; */
}
.step-name {
  margin-bottom: 0;
  font-size: 16px;
  color: #adadad;
  /* margin-top: 0; */
}
.step-status {
  margin-top: 0;
  font-size: 12px;
  color: #aeaeae;
  /* margin-bottom: 0; */
}
.step-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.question-logo {
  background: #f9f9f9;
  border-radius: 12px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar_layout {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-bottom: 94px;
}
.nav-footer-icons {
  display: flex;
  justify-content: space-between;
  padding: 16px 17px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.navigation_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}
.footer-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  border-radius: 8px;
  width: 41px;
  height: 41px;
}
.footer-icon img {
  width: 20px;
  height: 20px;
}
.arrow-img {
  width: 10px !important;
  height: 10px !important;
}
.profile-icon {
  display: flex;
  align-items: center;

  width: 59px;
}
.profile-icon .arrow-img {
  margin-left: 5px;
}
.moreInfoIcon-img {
  width: 28px !important;
  height: 28px !important;
}

.selectedBackground {
  background: #f0f6ff;
}

.completedQuest {
  background: rgba(232, 254, 233, 0.5);
}

.left_nav_icon {
  width: 100%;
  height: 50px;
}

/* pending */
#CLIENTANDACCESS .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/757281e7-77eb-4179-93bb-7a5c328656b4)
    no-repeat center center;
}
/* success */
#CLIENTANDACCESS .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/08e909eb-7821-4fbc-b793-edaef705d0c4)
    no-repeat center center;
}
/* ongoing */
#CLIENTANDACCESS .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/b5bf347d-0cea-4d0d-a963-2ddaff7dbdaf)
    no-repeat center center;
}

#onboardingInfo .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/757281e7-77eb-4179-93bb-7a5c328656b4)
    no-repeat center center;
}
#onboardingInfo .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/08e909eb-7821-4fbc-b793-edaef705d0c4)
    no-repeat center center;
}
#onboardingInfo .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/b5bf347d-0cea-4d0d-a963-2ddaff7dbdaf)
    no-repeat center center;
}
#read-access .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/1ad5c543-783d-4313-9e7b-1fbefc0f0c7a)
    no-repeat center center;
}
#read-access .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/307e5528-ae07-4c2c-bf85-2f3a948a077e)
    no-repeat center center;
}
#read-access .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/7ac90230-b327-4fd9-b644-dfd6371fad72)
    no-repeat center center;
}

#ONBOARDING .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/a8be7304-6b4e-4364-a9f8-05deec32ae96)
    no-repeat center center;
}
#ONBOARDING .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/a7120467-b969-4da7-bf2b-9a7f6f7e3b1c)
    no-repeat center center;
}
#ONBOARDING .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/72cf8dd4-658e-4f27-8f33-fc98417e8028)
    no-repeat center center;
}

#ONBOARDINGDOC .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/a8be7304-6b4e-4364-a9f8-05deec32ae96)
    no-repeat center center;
}
#ONBOARDINGDOC .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/a7120467-b969-4da7-bf2b-9a7f6f7e3b1c)
    no-repeat center center;
}
#ONBOARDINGDOC .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/72cf8dd4-658e-4f27-8f33-fc98417e8028)
    no-repeat center center;
}
/* pending */
#accessSharing .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/77f34e97-955e-4529-8b22-f8b800e84ea1)
    no-repeat center center;
}
/* success */
#accessSharing .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/8b78f6c9-939a-4e8f-990f-1b57cd062973)
    no-repeat center center;
}
/* ongoing */
#accessSharing .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/a1628c0f-4821-4623-a6ba-47afdb1db71b)
    no-repeat center center;
}
#form .left_nav_icon {
  background: url(../../../assets/icons/nav/grey-form-icon.svg) no-repeat center
    center;
}
#form .completedQuest .left_nav_icon {
  background: url(../../../assets/icons/nav/green-form-icon.svg) no-repeat
    center center;
}
#form .selectedBackground .left_nav_icon {
  background: url(../../../assets/icons/nav/blue-form-icon.svg) no-repeat center
    center;
}
/* pending */
#StrategyDeck .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/76d45c56-53ef-44df-aa55-0d720c9cbabb)
    no-repeat center center;
}
/* success */
#StrategyDeck .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/635e7fae-79ba-4091-82b4-d15efd7ea5f6)
    no-repeat center center;
}
/* ongoing */
#StrategyDeck .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/6fb369a5-f3d2-4f1d-b9c8-4463df886c1e)
    no-repeat center center;
}

#CampaignCreationFB .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/fcf9d16d-2c7b-4461-a808-b086163b3872)
    no-repeat center center;
}
#CampaignCreationFB .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/4678c7bf-c4e4-43ff-a626-4a78c66977ef)
    no-repeat center center;
}
#CampaignCreationFB .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/0119d1ee-13ea-4f90-9d8b-e45e3a02f877)
    no-repeat center center;
}

#approval .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/dbd04cbf-a10f-4df9-b33d-9a1a4f8ca4f0)
    no-repeat center center;
}
#approval .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/45866776-e759-4113-803f-ee7f769ef23f)
    no-repeat center center;
}
#approval .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/9549ab2a-5553-4363-bca4-909cbe027cee)
    no-repeat center center;
}

/* #ACCOUNT_ONBOARDING .left_nav_icon {
  background: url(../../../assets/icons/nav/grey-onboarding-icon.svg) no-repeat
    center center;
}
#ACCOUNT_ONBOARDING .completedQuest .left_nav_icon {
  background: url(../../../assets/icons/nav/green-onboarding-icon.svg) no-repeat
    center center;
}
#ACCOUNT_ONBOARDING .selectedBackground .left_nav_icon {
  background: url(../../../assets/icons/nav/blue-onboarding-icon.svg) no-repeat
    center center;
} */

#ACCOUNT_ONBOARDING .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/fe34de17-c567-4c82-954d-d184f73beb98)
    no-repeat center center;
}
#ACCOUNT_ONBOARDING .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/b3a32fb7-d326-4a31-ba9e-8c71cb7dcfe3)
    no-repeat center center;
}
#ACCOUNT_ONBOARDING .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/e26572f1-b659-474c-b70c-eb6305b6bb85)
    no-repeat center center;
}

#PROPOSAL_INPUTS .left_nav_icon {
  background: url(../../../assets/icons/nav/grey-form-icon.svg) no-repeat center
    center;
}
#PROPOSAL_INPUTS .completedQuest .left_nav_icon {
  background: url(../../../assets/icons/nav/green-form-icon.svg) no-repeat
    center center;
}
#PROPOSAL_INPUTS .selectedBackground .left_nav_icon {
  background: url(../../../assets/icons/nav/blue-form-icon.svg) no-repeat center
    center;
}

#ACCOUNT_SCOPE_OF_WORK_AND_PRICING .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/0f438951-deb4-4442-806f-1da477b346ff)
    no-repeat center center;
}
#ACCOUNT_SCOPE_OF_WORK_AND_PRICING .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/2fc695dd-6002-420a-a1b3-0742470c6689)
    no-repeat center center;
}
#ACCOUNT_SCOPE_OF_WORK_AND_PRICING .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/de628ef2-cd7e-4cd3-9a10-ea94f40edd59)
    no-repeat center center;
}

#AUDIT_FOR_GOOGLE .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/dcac5b1c-6c21-47f2-8e49-c21f5122ff4a)
    no-repeat center center;
}
#AUDIT_FOR_GOOGLE .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/88e73a27-5146-461e-87d1-76df972e9e6b)
    no-repeat center center;
}
#AUDIT_FOR_GOOGLE .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/c8e281e6-ba32-4696-873c-740ac4fbfc51)
    no-repeat center center;
}

#AUDIT_FOR_FACEBOOK .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/686af691-d632-495f-9f51-0541712754c7)
    no-repeat center center;
}
#AUDIT_FOR_FACEBOOK .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/6d2ddaa7-ff51-44dd-b683-fa2207ac0708)
    no-repeat center center;
}
#AUDIT_FOR_FACEBOOK .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/5328fe39-caaf-4ac5-afbc-0218599cfc97)
    no-repeat center center;
}

.amlayout #questionnaire .left_nav_icon {
  background: url(../../../assets/icons/nav/grey-proposalam-icon.svg) no-repeat
    center center;
}
.amlayout #questionnaire .completedQuest .left_nav_icon {
  background: url(../../../assets/icons/nav/green-proposalam-icon.svg) no-repeat
    center center;
}
.amlayout #questionnaire .selectedBackground .left_nav_icon {
  background: url(../../../assets/icons/nav/blue-proposalam-icon.svg) no-repeat
    center center;
}
/* 
.amlayout #proposal .left_nav_icon{ background: url(../../../assets/icons/nav/grey-strategy-icon.svg) no-repeat center center;}
.amlayout #proposal .completedQuest .left_nav_icon{background: url(../../../assets/icons/nav/green-strategy-icon.svg) no-repeat center center;}
.amlayout #proposal .selectedBackground .left_nav_icon{background: url(../../../assets/icons/nav/blue-strategy-icon.svg) no-repeat center center;} */

.amlayout #STRATEGY_INPUT .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/fa83f9cf-ba62-4f7d-94f0-d78f3712f5f8)
    no-repeat center center;
}
.amlayout #STRATEGY_INPUT .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/51d9b704-0f7a-42e8-985f-810e73577384)
    no-repeat center center;
}
.amlayout #STRATEGY_INPUT .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/96f71cb6-ab2a-456d-b0e0-c20f6d905d3a)
    no-repeat center center;
}
/* pending */
#StrategyCreationGoogle .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/b842fa1d-8a0c-45f2-aa42-f6ba1d1491e7)
    no-repeat center center;
}
/* success */
#StrategyCreationGoogle .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/bbaefc86-5e17-4198-b9bc-bbe570f8c988)
    no-repeat center center;
}
/* ongoing */
#StrategyCreationGoogle .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/e35d5cdf-c3fa-4941-bafe-b573d90de006)
    no-repeat center center;
}

#StrategyCreationFacebook .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/acc14b9f-23dd-4b78-98d9-0b785fc90f83)
    no-repeat center center;
  background-size: 20px auto;
}
#StrategyCreationFacebook .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/9045e6a3-3156-4b7c-b90e-792b531adf2a)
    no-repeat center center;
}
#StrategyCreationFacebook .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/b86f5530-9080-4e82-a6ab-9e8e5d5c87bd)
    no-repeat center center;
}
/* pending */
#AccountAnalysis .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/9f980314-aafa-4647-84f7-5a74703ad3d6)
    no-repeat center center;
  background-size: 20px auto;
}
/* success */
#AccountAnalysis .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/63479208-eabe-49c6-acde-0333e20b65f1)
    no-repeat center center;
}
/* ongoing */
#AccountAnalysis .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/7797e82f-fe0c-4366-9cfe-caad43690dd4)
    no-repeat center center;
}

/* pending */
#CampaignCreationGoogle .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/93425277-5048-4850-a827-13d6d5aa0f63)
    no-repeat center center;
  background-size: 20px auto;
}
/* success */
#CampaignCreationGoogle .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/765cfbd8-d8cc-46b4-b16a-8d3ccfe1fc7c)
    no-repeat center center;
}
/* ongoing */
#CampaignCreationGoogle .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/93ceb73b-4e3a-4361-b43a-c0b8286eb855)
    no-repeat center center;
}

/* pending */
#CampaignPreviewGoogle .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/c62f1dd6-a72a-4dc5-b57c-8fac9de0dd2e)
    no-repeat center center;
  background-size: 20px auto;
}
/* success */
#CampaignPreviewGoogle .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/ad70400a-b615-4d87-ad86-06aea29a9c8b)
    no-repeat center center;
}
/* ongoing */
#CampaignPreviewGoogle .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/96ac4ec7-232a-455c-b1d7-4dff1abed8b1)
    no-repeat center center;
}

#CampaignPreviewFB .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/24bfc7f5-a788-4b03-ab77-5327c78435de)
    no-repeat center center;
  background-size: 20px auto;
}
#CampaignPreviewFB .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/02337253-8c7a-405f-a498-ba1e0f894007)
    no-repeat center center;
}
#CampaignPreviewFB .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/7bdce1d3-2d2d-4f24-b005-e5dd7c87d08d)
    no-repeat center center;
}

#AnalyticDashboard .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/9af0b308-a210-4c32-bd52-1c2b2e7afa76)
    no-repeat center center;
  background-size: 20px auto;
}
#AnalyticDashboard .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/7c5e11fa-48f5-418a-a3ae-8d0ba7d926d3)
    no-repeat center center;
}
#AnalyticDashboard .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/df1fc6a4-0a60-4eeb-9031-04bbe27ebea3)
    no-repeat center center;
}
/* PENDING  */
#CAMPAIGNPREVIEW .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/4b502a9c-f0d5-46cc-8c7b-66dbf45bdb49)
    no-repeat center center;
  background-size: 20px auto;
}
/* SUCCESS */
#CAMPAIGNPREVIEW .completedQuest .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/2a5d4a08-7ab0-4eb9-9401-98ce4a5f2cd7)
    no-repeat center center;
}
/* ONGOING */
#CAMPAIGNPREVIEW .selectedBackground .left_nav_icon {
  background: url(https://onboarding-backend-dev.pixis.ai/files/assets/72b509e7-c00e-4e70-8f69-c0b9360c9a8c)
    no-repeat center center;
}
