@font-face {
    font-family: 'Proxima Nova semi';
    src: url('../../../assets/fonts/ProximaNova-Semibold.woff2') format('woff2'),
        url('../../../assets/fonts/ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Bold';
    src: url('../../../assets/fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('../../../assets/fonts/ProximaNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('../../../assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('../../../assets/fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.proposal_layout{ display: flex; justify-content: center; align-items: center;}
.proposal_status_layout{ text-align: center; max-width: 700px; margin: auto;}
.setIcon{ background: #fff; max-width: 200px; display: block; margin: auto;background: #FFFFFF;
border: 0.5px solid #E9E9E9;
box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.04);    border-radius: 0px 50px 0px 50px;
overflow: hidden; margin-bottom: 40px;}
.setIcon img{ max-width: 100%; display: block;}
.proposal_status_title{font-family: 'Lato';font-weight: 700;font-size: 24px;line-height: 27px;text-align: center;margin: 0;color: #000000; margin-bottom: 9px;}
.proposal_status_typography{font-family: 'Lato';font-weight: 400;font-size: 16px;line-height: 26px;text-align: center;color: #6F6C90; margin: 0; margin-bottom: 32px;}
.hide{ display: none;} 
.changes_proposal_layout{background: #FFFFFF;box-shadow: 0px 12px 80px rgba(0, 0, 0, 0.05);border-radius: 24px; max-width: 510px; width: 100%; margin: auto; text-align: center; padding:54px 32px 30px; position: relative;}
.changes_notes_icon{ position: absolute; top: -81px; left: 0; right: 0;}
.notes_text{ margin: 0;font-family: 'Inter';font-weight: 500;font-size: 16.8122px;line-height: 19px;color: #6F6C90;}
.notes_title{ margin: 0;font-family: 'Inter';font-weight: 700;font-size: 22.4162px;line-height: 33px;color: #170F49;}
.notes_textarea_wrapper{ margin: 26px 0 33px;}
.notes_textarea{background: #F6F6F6;border: 1px solid #F6F6F6;border-radius: 12px; width: 100%; resize: none; padding: 15px; height: 330px; font-family: 'Inter';font-weight: 400;font-size: 16px;line-height: 24px;color: #979797;}
.notes_textarea:focus{ outline: none;}
.modal_icon{ max-width: 144px; margin: auto;}
.modal_icon img{ display: block; width: 100%; height: auto;}
.proposal_flex_top{ align-items: flex-start;}

.preview_layout{ max-width: 90%; margin: auto;}
.overview_title{ position: relative;  width: 100%; }
.overview_title span{ background: #fff; position: relative; z-index: 1; padding-right: 10px;} 
.overview_title:after{ border-bottom: 1px solid rgba(0, 0, 0, 0.12); width: 100%; content: ''; position: absolute; left: 0; top: 50%; transform: translate(0,-50%);}
.preview_head{ text-align: center; padding: 54px 0 0px; position: relative;} 
.preview_head h4{ margin: 0;font-family: 'Inter';font-weight: 700;font-size: 18px;line-height: 20px;letter-spacing: 0.1em;text-transform: uppercase;color: #4A3AFF;}
.preview_head h2{ margin: 0;font-family: 'Inter';font-weight: 700;font-size: 35px;line-height: 62px;color: #170F49;}
.download_pdf{background: #F8F8F8 url('../../../assets/icons/proposal/download.svg') no-repeat center center; width: 40px; height: 40px; display: block;border-radius: 8.25px; position: absolute; top: 23px; right: 27px;}
.inner_proposal{background: #fff;  border-radius:8px; }
.proposal_layout { padding-right: 0px;}

.preview_footer {
    position: fixed;
    bottom: 0;
    left: 307px;
    right: 0;
    background: #fff;
    z-index: 11;
    padding:16px 24px; display: flex; justify-content: space-between; align-items: center;border-top: 1px solid #DBDEE2;}