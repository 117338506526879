.c-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0px 25px 30px rgba(80, 80, 159, 0.06);
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.875em 1.5em;
    gap: 0.625em;
    border-radius: 0.375em;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }
  }

  &__plain-button {
    border: none;
    color: #0869FB;
    background-color: transparent;
  }

  &__cta-button {
    background: #0869FB;
    color: #FFFFFF;
    border: none;
  }
}