.center{
    text-align: center;
    margin: auto;
    width: 50%;
    
}
.img-submit{
    display: block;
    margin-left: auto;
    margin-right: auto;

}
.submit-title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    color: #000000;
}
.submit-desc{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #6F6C90;
    margin-bottom: 15px;
}