.targeting-container {
  width: 50%;
  background: #ffffff;
  border: 2px solid #e6f6ff;
  border-radius: 8px;
  margin-top: 26px;
}
.targeting-header__title {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #333333;
  padding: 20px 0 16px 21px;
  border-bottom: 1px solid #f0f0f0;
}
.targeting-form {
  padding: 0 22px;
  margin-top: 1rem;
}
.targeting-form-element__title {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #333333 !important;
}
.targeting-form-element {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  margin-top: 9px;
  margin-bottom: 23px;
}
.age-form-element {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 50%;
}
.gender-form-element {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.divider-age-range {
  width: 12px;
  height: 0px;
  border: 0;
  border-top: 2px solid #d3d3d3;
}
