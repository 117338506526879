.pmax-image-upload-message-status-container {
  .image-upload-message-status-container {
    padding: 24px;
    .image-uploaded-container {
      .image-upload-success,
      .image-upload-failed {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 16px;
      }
      .image-success-uploaded-list,
      .image-failed-uploaded-list {
        .image-upload {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-bottom: 20px;
          .image {
            width: 80px;
            height: 85px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .image-details {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        }
      }
    }
  }
  .pmax-proceed-button-container {
    background: #ffffff;
    box-shadow: 0px -1px 26px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
    height: 71px;
    display: flex;
    justify-content: end;
    padding-right: 16px;
    align-items: center;
    .proceed-button {
      width: 105px;
      height: 40px;
      background: #0869fb;
      border-radius: 8px;
      color: white;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-transform: capitalize;
    }
  }
}
