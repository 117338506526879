
.verizon-selection{
    position: relative;
    background: #FFFFFF;   
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.04);
    width: 317px;
    margin-left: 24; 
    border-radius: 25px 0px;
    margin: 10px
}
.verizon-title{
    position: relative;
    height: 90px
}
.mainContentVerizon{
    position: absolute;
    top: 14px;
    right: 20px;
    padding: 4px;
}

.facebook-verizon{
    width: 288px;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background: #FFFFFF;
    box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
    border-radius: 8px;
    margin-bottom: 12px;
}

.social_status_tag.warning {color: #F8862D;background: #FEF3EA;}
.social_status_tag.success {color: #12C8B2;background: #E7F9F7;}
/* .verizon{
  
} */