.pmax-campaign-creation-container {
  height: calc(100vh - 1.6rem);
  overflow: auto;
}

.pmax-campaign-creation-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
}

.pmax-campaign-creation-header__title {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 25px !important;
  /* text-align: center; */
  text-transform: uppercase !important;
  color: #170f49 !important;
  line-height: 48px !important;
}
.pmax-campaign-creation-header__description {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  text-align: center !important;
  color: #6f6c90 !important;
}

.pmax-campaign-sub-container {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 2.26013px 9.7939px rgba(242, 242, 242, 0.6);
  border-radius: 6.80095px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
  width: 100%;
  padding: 0.75rem;
  /* position: relative; */
}
.pmax-indicator {
  width: 20px;
  background-color: #eaf2ff;
  border-top-left-radius: 6.80095px;
  border-bottom-left-radius: 6.80095px;
}
.pmax-campaign-creation-field-header__title {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #333333 !important;
  border-bottom: 0.609177px solid #f0f0f0;
  padding-bottom: 0.8rem;
  padding-top: 0.5rem;
}
.pmax-campaign-creation-field-header {
  position: relative;
}
.pmaxdetailButton {
  /* width: 111px; */
  height: 32px;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #0869fb !important;
  background: #eaf2ff !important;
  border-radius: 3px !important;
  border: 0 !important;
  text-transform: none !important;
  position: absolute !important;
  right: 8px;
  top: -2px;
}

.pmax-campaign-form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  /* justify-content: space-evenly; */
  margin: 1.25rem 0;
  width: 690px;
}
.pmax-campaign-form-container .pmax-campaign-form-element {
  width: 173px;
  /* height: 58px; */
  background: #ffffff;
  box-shadow: 0px 0px 31.3379px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.pmax-form-input-element .MuiSelect-select:focus {
  background: transparent !important;
}
.pmax-form-input-element2 .MuiSelect-select:focus {
  background: transparent !important;
}
.pmax-campaign-form-container .pmax-campaign-form-element2 {
  width: 280px;
  /* height: 58px; */
  background: #ffffff;
  box-shadow: 0px 0px 31.3379px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.pmax-icon-container {
  width: 46px;
  height: 46px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(23, 15, 73, 0.04);
  border-radius: 9.04506px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pmax-form-input-element {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.pmax-form-input-label {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: #6f767e !important;
}

.pmax-form-input-element2 {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 220px;
}
.pmax-form-input-label2 {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: #6f767e !important;
  width: 220px;
}

.pmax-dual-form-element {
  width: 355px;
  height: 147px;
  background: #ffffff;
  box-shadow: 0px 0px 31.3379px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 8px 6px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 17.97px;
}

.pmax-form-input-label3 {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: #6f767e !important;
  width: 100%;
}
