.banner_left {
  border-radius: 1rem;
  width: 100%;
  background-color: white;
  /* height: 35vh; */
  position: relative;
  max-width: 511px;
}
.hero_banner {
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 29px 29px 27px 36px;
}
.banner_left span {
  color: #4a3aff;
  font-family: 'Inter';
  font-weight: 600;
  font-size: large;

  text-transform: uppercase;
  letter-spacing: 2px;
}

.banner_left h2 {
  margin: 0px 0px;
  font-size: 35px;
  text-transform: uppercase;
  line-height: 62px;
  color: #170f49;
  font-family: 'Inter';
  font-weight: 700;
}
.banner_left p {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #6f6c90;
  margin: 0;
}
.bubble_banner {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  border: 1px solid #e9e9e9;
  padding: 14px 0px 18px;
  border-radius: 2rem;

  line-height: 2.5rem;
  width: 487px;
  box-shadow: 0px 10.9302px 73.3835px rgba(0, 0, 0, 0.05);
  margin-left: 30px;
}
.bubble_banner::before {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;

  border-left: 10px solid transparent;
  border-right: 30px solid #e9e9e9;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  left: -41px;
  top: 50%;
  transform: translate(0, -50%);
}
.bubble_banner::after {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 30px solid white;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  left: -39px;
  top: 50%;
  transform: translate(0, -50%);
}

.bubble_banner .single_title_start {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: #170f49;
  margin: 13px 0;
}
.bubble_banner img {
  display: block;
}

.grid_home_page {
  display: flex;
  justify-content: space-between;
}
.grid_colum {
  width: calc(50% - 8px);
}
.grid_learning_column {
  background: #fff;
  border-radius: 8px;
  padding: 22px 20px 5px;
}
.grid_account_column {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 22px 20px;
  min-height: 364px;
}
.grid_quick_column {
  background: #fff;
  border-radius: 8px;
  padding: 22px 20px;
}
.home_title {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0 0 23px;
  color: #4a3aff;
}
.home_social {
  position: relative;
}
.social_home {
  padding: 12px 44px 12px 12px;
  background: #ffffff;
  box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  justify-content: space-between;
}
/* .social_home:after {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  content: '';
  background: url(../../../assets/icons/right_arrow.svg) no-repeat center center;
  width: 16px;
} */
.social_name_title {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  margin: 0;
  margin-left: 8px;
}
.social_list_home {
  display: flex;
  align-items: center;
}
.social_status_tag p {
  margin: 0;
  padding: 5px 10px 6px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
}
.social_status_tag.warning p {
  color: #f8862d;
  background: #fef3ea;
}
.social_status_tag.success p {
  color: #12c8b2;
  background: #e7f9f7;
}
.social_name_title span {
  color: #d6d6d6;
  margin: 0 5px;
}
.account_connect_number {
  background: rgba(8, 105, 251, 0.07);
  font-family: 'Inter';
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #0869fb;
  width: 20px;
  text-align: center;
  border-radius: 50%;
  margin-left: 8px;
}
.home_cards {
  display: flex;
  justify-content: space-between;
}
.card_block_home {
  background: #ffffff;
  border: 0.216667px solid #e9e9e9;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 0 0;
  flex-grow: 1;
  flex-basis: 0;
  margin: 0 5px;
}
.card_block_home span {
  background: #e6f6ff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 23px;
  text-align: center;
  color: #0869fb;
}
.card_block_home p {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #171717;
  margin: 8px 0 13px;
}
.card_block_home i {
  width: 17px;
  height: 17px;
  margin: auto;
}
.card_block_home i img {
  display: block;
  width: 100%;
  height: auto;
}

.learning_home_row {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 0.312785px solid #e9e9e9;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 20px 16px 22px;
  margin-bottom: 18px;
  transition: 0.3s ease;
  position: relative;
}

.learning_home_row:hover {
  background: #f5f5f5;
}
.learning_home_img {
  margin-right: 16px;
  min-width: 172px;
  width: 172px;
}
.learning_home_img img {
  display: block;
  width: 100%;
  height: auto;
}

.learning_item_info h4 {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 0 0 5px 0;
}
.learning_item_info p {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  margin: 0;
}
.export_icon {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 6px;
  right: 6px;
  background: url(../../../assets/icons/common/export_icon.svg) no-repeat center
    center;
}

@media screen and (max-width: 1380px) {
  .card_block_home span {
    font-size: 9px;
  }
  .grid_quick_column {
    padding: 22px 10px;
  }
  .grid_account_column {
    padding: 22px 10px;
  }
  .grid_learning_column {
    padding: 22px 10px;
  }
  .card_block_home p {
    font-size: 11px;
  }
  .social_name_title {
    font-size: 12px;
  }
  .social_name_title span {
    margin: 0;
  }
  .social_home {
    padding: 12px 30px 12px 12px;
  }
}
