.googlebox {
  display: flex;
  flex-direction: column;
  width: 98%;
  padding-left: 1.5rem;
}
.available span {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #999999;
}
.googlebox h2 {
  font-size: 20px;
  font-family: "Inter";
  line-height: 28px;
  color: #242731;
  font-weight: 600;
}
.line {
  border-bottom: 1px solid #f0f0f0;
  padding: 18px 16px;
  width: 50%;
}
.assignee span {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #999999;
}

.ai {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-top: 5px;
}

.ai span {
  color: #333333;
}
.ai p {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #999999;
}
.ft {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.options {
  padding-top: 2rem;
  padding-left: 2rem;
}

.footer_row {
  border-top: 1px solid #dbdee2;
  padding: 18px 25px;
}
.footer_down {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}
.download {
  background: none;
  border: 1px solid #0869fb;
  color: #0869fb;
  font-family: "Inter";
  font-weight: 600;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 10px;
}
.download:hover {
  cursor: pointer;
}

.select-ad-modal {
  font-size: 14px;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;

    img {
      width: 4em;
      height: 4em;
    }

    span {
      color: #2e3044;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Lato;
      font-size: 1.5em;
      font-style: normal;
      font-weight: 700;
      line-height: 1.6681em;
      text-transform: capitalize;
    }
  }

  &__inner-content {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  }

  &__search-field {
    .MuiInputBase-root {
      padding-bottom: 0.5em;
      color: #ccc;
    }

    .MuiInputBase-root::before {
      border-color: #cccccc !important;
    }

    input,
    input::placeholder {
      color: #ccc;
      font-family: Inter;
      font-size: 0.875em;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    input {
      color: darken($color: #ccc, $amount: 30);
      padding-left: 0.4em;
    }
  }

  &__h3 {
    color: var(--color-dark-40, #999);
    font-family: Inter;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
    margin: 0;
  }

  &__accounts-list {
    display: grid !important;
    grid-template-columns: repeat(2, calc(50% - (1.5em / 2)));
    grid-template-rows: repeat(auto-fill, 3.4494em);
    gap: 1.5em;

    list-style: none;
    height: 20em;
    overflow-y: auto;
    padding: 0;
    margin: 0;
  }

  .account-card {
    margin: 0 !important;
    border-radius: 0.75em;
    background: #fff;
    height: min-content;
    border: 1px solid #ededed;
    padding: 0.4em;
    width: 100%;

    display: flex;
    align-items: center;
    gap: 0.6em;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 0.3em;
    }

    &__name {
      color: #333;
      font-family: Inter;
      font-size: 0.875em;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25em;
    }

    &__account-id {
      color: #878787;

      font-family: Inter;
      font-size: 0.875em;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25em;

      .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding-top: 1.5em;
  }

  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.3em;
    padding: 3em 0 1em 0;
    margin-top: 72px;
  }

  &__error-title {
    color: #2e3044;
    font-family: Inter;
    font-size: 1.125em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25em;
    margin: 0;
    padding: 0;
  }

  &__error-description {
    color: #6d6e7c;
    text-align: center;
    font-family: Inter;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375em;
  }
}
