.ad-level-header {
  padding: 0.65rem 0 0.5rem 1rem;
  border-bottom: 1px solid #f0f0f0;
}
.ad-group-navigation-and-results {
  margin: 0.75rem 1rem 0.5rem 1rem;
}
.ad-level-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 13.5px;
  flex-grow: 1;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 0.75rem;
}
.ad-group-results {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}
.ad-group-detail {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.04);
  border-radius: 5px;
  flex-grow: 1;
}
.ad-group-result__title {
  border-bottom: 1px solid #f0f0f0;
  width: 95%;
  margin: 0 auto !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #444444;
  padding: 0.5rem 0;
}
.ad-group-result__description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #626262;
}
.ad-level-description-container {
  display: flex;
  flex-grow: 1;
  width: 95%;
  margin: auto;
  padding: 0.35rem 0;
  justify-content: space-between;
}
.ad-list-result-container {
  margin-top: 1.5rem;
  height: 48vh;
  overflow: auto;
}
.add-headline-container {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0869fb;
  border-top: 1px solid #f0f0f0;
  padding: 1.25rem 0;
  cursor: pointer;
}
.preview-ad-list-results {
  width: 35.75rem;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  /* height: 48vh; */
  /* overflow: auto; */
}
.preview-ad-list-result {
  /* width: 36.12rem; */
  background: #ffffff;
  border: 1.2268px solid #f2f2f2;
  border-radius: 9.81444px;
  margin-left: auto !important;
  margin-right: auto !important;
  /* display: flex; */
  /*margin-left: 0 !important;
  margin-right: 0 !important; */
  margin-top: 1.5rem;

  padding: 21px 36.21px 21px 19.68px;
}
.preview-ad-list-result__heading {
  font-family: 'Roboto';
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14.7217px !important;
  color: #1e14a5;
}
.preview-ad-list-result__url {
  font-family: 'Roboto';
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14.7217px !important;
  color: #2a652a;
}
.preview-ad-list-result__description {
  font-family: 'Roboto';
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14.7217px !important;
  color: #000000;
}
