.conversion-container {
  width: 50%;
  background: #ffffff;
  border: 2px solid #e6f6ff;
  border-radius: 8px;
  margin-top: 26px;
  position: relative;
}
.conversion-header__title {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #333333;
  padding: 20px 0 16px 21px;
  border-bottom: 1px solid #f0f0f0;
}
.conversion-form {
  display: flex;
  gap: 19px;
  padding: 0 22px;
  margin-top: 9px;
}
.conversion-form-element__title {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #333333 !important;
}
.conversion-form-element {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 9px;
  width: 50%;
  /* gap: 19px; */
}
.conversion-flow-steps {
  margin-top: 18px;
  /* border-left: 1px dashed #a3a3a3; */
  padding: 0 22px;
  position: relative;
  z-index: 99;
}
.current-status {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #0869fb;
  color: white;
  font-family: Inter;
  font-size: 9px;
  font-weight: 600;
  line-height: 11px;
  letter-spacing: 0px;
}
.complete-status {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #1abfa1;
  color: white;
  font-family: Inter;
  font-size: 9px;
  font-weight: 600;
}
.pending-status {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #f4f4f4;
  color: #818181;
  font-family: Inter;
  font-size: 9px;
  font-weight: 600;
}
.disabled-text {
  color: #333333;
}
.conversion-flow-step {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  margin-bottom: 13px;

  /* border-left: 1px dashed #a3a3a3; */
}
.flow-line {
  border: 1px dashed #a3a3a3;
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 31.5px;
  z-index: -1;
}
