.button-container {
    width: 102px;
    height: 32px;
    background: #fafafa;
    border: 1px solid #e4e4e4;
    border-radius: 60px;
    display: flex;
    position: relative;
    cursor: pointer;
  }
  .selected-best {
    width: 50px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 4px;
    top: 2px;
    background: #0869fb;
    box-shadow: 11px 6px 5px rgba(0, 0, 0, 0.01), 6px 3px 4px rgba(0, 0, 0, 0.05),
      3px 2px 3px rgba(0, 0, 0, 0.09), 1px 0px 2px rgba(0, 0, 0, 0.1),
      0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 54px;
  }
  .selected-worst {
    width: 50px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 4px;
    top: 2px;
    background: #0869fb;
    box-shadow: 11px 6px 5px rgba(0, 0, 0, 0.01), 6px 3px 4px rgba(0, 0, 0, 0.05),
      3px 2px 3px rgba(0, 0, 0, 0.09), 1px 0px 2px rgba(0, 0, 0, 0.1),
      0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 54px;
  }
  .worst {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .best {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }