.mp{
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-top: 5px;
}

.mp span{
    color: #333333;
}
.mp p{
    color:#999999;
}
.st{
    display: flex;
    align-items: center;
    padding-right: 5rem;
    padding-top: 5px;
   }

.radio{
   display: flex;
   align-items: center;
    border: 1px solid #F0F0F0;
    width: fit-content;
    padding-left: 5px;
    padding-right: 2rem;
    box-shadow: #999999;
    border-radius: 12px;
    margin-bottom: 2rem;
}    
.available{
    padding-top: 2rem;
}
/* .available span{
    font-family: sans-serif;
    color: #999999;
    
} */

