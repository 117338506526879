.pmax-campaign-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding: 20px 16px 12px;
  border-bottom: 2px solid #e6f6ff;
  .pmax-field-value {
    color: #878787;
  }
}
.pmax-campaign-asset-group-container {
  padding: 16px 16px 20px;
  overflow-x: hidden;
  padding-bottom: 74px;
  .pmax-campaign-action {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pmax-campaign-path-container {
      display: flex;
      align-items: center;
      gap: 10px;
      .pmax-campaing-path-url-section {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-self: flex-end;
        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
          border-radius: 7.7px;
        }
      }
      .campaign-separator {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #8b8b8b;
        padding-top: 1.5rem;
      }
    }
  }
  .pmax-campaign-targeting-and-detailed-view {
    display: flex;
    gap: 22px;
    margin-top: 22px;
    .pmax-campaign-target-heading {
      border-bottom: 1.92886px solid #e6f6ff;
    }
    .pmax-existing-new-segment-view {
      flex-basis: 41%;
      display: flex;
      flex-direction: column;
      // gap: 17px;
      border: 1.92886px solid #e6f6ff;
      border-radius: 7.71545px;
      min-height: 472px;
      max-height: 472px;
      overflow-y: scroll;
      padding-bottom: 24px;
      .pmax-age-household-income {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
        margin-top: 15px;
        .pmax-campaign-age-range {
          display: flex;
          align-items: center;
          padding-left: 16px;
          gap: 12px;
          .separator {
            width: 11.14px;
            height: 0;
            border-bottom: 1.92886px solid #d3d3d3;
          }
          .MuiTypography-root {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12.5376px;
            line-height: 19px;
            color: #333333;
            margin-left: -4px;
          }
        }
      }
      .pmax-gender-parental-status {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
        margin-top: 15px;
        .form-checkbox-style {
          display: flex;
          flex-direction: row;
          gap: 4px;
          padding-left: 16px;
          .MuiTypography-root {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12.5376px;
            line-height: 19px;
            color: #333333;
            margin-left: -4px;
          }
        }
      }
      .pmax-campaign-call-to-action {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        margin-top: 15px;
        // justify-content: center;
        .MuiSelect-select:focus {
          background-color: transparent !important;
        }
      }
      .pmax-existing-segment {
        padding: 14px 16px;
        display: flex;
        align-items: center;
        .MuiOutlinedInput-notchedOutline {
          border: 0;
        }
        .MuiTypography-root {
          font-size: 13.502px;
          line-height: 0;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          color: #333333;
        }
        .existing-segement-select-box {
          .MuiSelect-select:focus {
            background-color: transparent !important;
          }
        }
      }
      .pmax-new-segment {
        border: 1.92886px solid #e6f6ff;
        border-radius: 7.71545px;
        .new-segment-radio {
          .css-ahj2mt-MuiTypography-root {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 13.502px;
            line-height: 19px;
            color: #333333;
          }
        }
        .interests-purchase-radio {
          .css-ahj2mt-MuiTypography-root {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            color: #333333;
          }
        }
        .interests-purchase-radio,
        .search-for-terms-radio {
          .css-ahj2mt-MuiTypography-root {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            color: #333333;
          }
        }
        .google-search-term-list {
          min-height: 220px;
          max-height: 220px;
          overflow-y: scroll;
          padding: 12px 13px 0 10px;
        }
        .google-search-term {
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
          border-radius: 8px;
          padding: 8px 15px;
          margin-bottom: 19px;
        }
      }
    }
    .p-max-detailed-view-container {
      flex-basis: 59%;
      display: flex;
      flex-direction: column;
      gap: 17px;
      .pmax-detailed-view {
        border: 2px solid #e6f6ff;
        border-radius: 8px;
        padding-top: 7px;
        // padding: 7px 15px 0 15px;
        .pmax-navigation-controls {
          display: flex;
          margin-bottom: 16px;
          padding: 0px 15px 0 15px;
          gap: 10px;
          .pmax-control {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            gap: 2px;
            // opacity: 0.5;
            padding: 8px 7.5px;
            // border-bottom: 1px solid #f0f0f0;
          }
        }
        .pmax-theme-search {
          display: flex;
          align-items: center;
          gap: 16px;
          padding-bottom: 15px;
          margin-bottom: 15px;
          border-bottom: 2px solid #e6f6ff;
          padding-left: 20px;
          .search-button-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 8px;
            .search-button {
              width: 116px;
              height: 40px;
              background: rgba(8, 105, 251, 0.07);
              border-radius: 8px;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #0869fb;
              text-transform: capitalize;
            }
          }
          .MuiOutlinedInput-root {
            border-radius: 8px;
          }
        }
        .pmax-theme-list {
          padding-left: 25px;
          min-height: 316px;
          max-height: 316px;
          overflow-y: scroll;
          margin-bottom: 15px;
        }
        .headline-container {
          padding-left: 16px;
          padding-right: 16px;
          min-height: 340px;
          max-height: 340px;
          overflow-y: scroll;
          margin-top: 24px;
          margin-bottom: 16px;
          .headline {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding-bottom: 12;
            gap: 16px;
            margin-bottom: 16px;
            .headline-text {
              background: #ffffff;
              border: 1px solid #f0f0f0;
              box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.04);
              border-radius: 5px;
              width: 98%;
              padding-left: 15px;
              min-height: 36px;
              padding-top: 4px;
              padding-bottom: 4px;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 31px;
            }
          }
        }
        .description-container {
          padding-left: 16px;
          padding-right: 16px;
          min-height: 338px;
          max-height: 338px;
          overflow-y: scroll;
          margin-top: 24px;
          margin-bottom: 16px;
          .description {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding-bottom: 12;
            gap: 16px;
            margin-bottom: 16px;
            .description-text {
              background: #ffffff;
              border: 1px solid #f0f0f0;
              box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.04);
              border-radius: 5px;
              width: 98%;
              height: 68px;
              padding-left: 15px;
              padding-top: 4px;
              padding-bottom: 4px;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 31px;
            }
          }
        }
        .image-view-container {
          padding: 6px 27px 18px 27px;
          position: relative;
          z-index: 1;
          .image-list-container {
            display: flex;
            flex-wrap: wrap;
            gap: 28px;
            min-height: 304px;
            max-height: 304px;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 0.5rem;
            .image {
              width: 162px;
              height: 171px;
              margin-bottom: 112px;
              position: relative;

              .delete-container {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff3f3;
                border-radius: 50%;
                position: absolute;
                top: -8px;
                right: -8px;
                z-index: 100;

                img {
                  width: 14px;
                  height: 14px;
                }
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .play-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 68px;
                height: 68px;
              }
              .name {
                margin-top: 4px;
              }
              .resolution {
                margin-top: 6px;
              }
            }
          }
          .image-resolution-message {
            display: flex;
            gap: 16px;
            padding-bottom: 18px;
            // align-items: center;
          }
        }
        .video-view-container {
          padding: 6px 27px 18px 27px;
          position: relative;
          z-index: 1;
          .yb-search-pmax-container {
            margin-bottom: 20px;
            .text-link-area {
              display: flex;
              align-items: center;
              gap: 16px;
              .MuiTextField-root {
                .MuiOutlinedInput-root {
                  height: 41.22px;
                  // border: 0;
                  border: 0.964432px solid #dddddd;
                  border-radius: 7.71545px;
                }
              }
            }
          }
          .video-list-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            min-height: 252px;
            max-height: 252px;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 0.5rem;
            .video-list {
              display: flex;
              gap: 8px;
              .video {
                width: 162px;
                height: 171px;
                margin-bottom: 38px;
                position: relative;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
                .play-icon {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 68px;
                  height: 68px;
                }
                .name {
                  margin-top: 4px;
                }
                .resolution {
                  margin-top: 6px;
                }
              }
            }
            .video-details {
              .link {
                display: flex;
                flex-direction: column;
                gap: 2.75rem;
                margin-top: 12px;
              }
              .remove-video {
                width: 64px;
                height: 17px;
                background: rgba(232, 75, 71, 0.09);
                border-radius: 4px;
                text-transform: capitalize;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 2px;
              }
            }
          }
        }
      }
    }
  }
}
