.campaign-type {
  display: flex;
  width: calc(100% - 2rem);
  margin: 2rem auto auto;
  background: #FFFFFF;
  border: .0625rem solid #F0F0F0;
  box-shadow: 0rem .1413rem .6121rem rgba(242, 242, 242, 0.6);
  border-radius: .4251rem;
}

.campaign-type__success-indicator {
  background-color: #EAF2FF;
  width: .625rem;
  /* height: 14.25rem; */
  border-top-left-radius: inherit;
  flex-grow: 0;
  flex-shrink: 0;
}

.campaign-type__success-indicator[data-is-published="true"] {
  background-color: rgba(26, 191, 161, 0.1);
}

.campaign-type__success-indicator[data-is-dirty="true"] {
  background-color: #EAF2FF !important;
}

.campaign-type__main {
  padding: 0.75rem;
  flex-grow: 1;
}

.campaign-type__main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.609177px solid #f0f0f0;
  padding: 0 0 0.8rem;
}

.campaign-type__title {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: .875rem !important;
  line-height: 1.0625rem !important;
  color: #333333;

  display: flex;
  align-items: center;
  gap: .5rem;
}

.campaign-type__view-details-button {
  height: 2rem;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: .75rem !important;
  line-height: 1.25rem !important;
  color: #0869fb !important;
  background: #eaf2ff !important;
  border-radius: .1875rem !important;
  border: 0 !important;
  text-transform: none !important;
}