.best-main{
    display: flex;
  align-items: center;
  justify-content: center;
  height: 556px;
  max-width: 100%;
  /* border-radius: 2rem; */
  background: url(./strategy-Icons/best-practices.png) no-repeat; 
  margin: 0rem 3.5rem;
  background-size:100% 100%;
}
.Expectation_head{
    /* height: 150.97px; */
    width: 100%;
    background: url(./strategy-Icons/creative-background.svg)no-repeat ;
    background-size:100%;
    margin-bottom: 40.47px;
}
.Video_head{
    /* height: 150.97px; */
    width: 100%;
    background: url(./strategy-Icons/videoEdit-background.svg)no-repeat ;
    background-size:100%;
    margin-bottom: 40.47px;
}