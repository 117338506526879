.analysis-main{
    display: flex;
  align-items: center;
  justify-content: center;
  height: 556px;
  max-width: 100%;
  /* border-radius: 2rem; */
  background: url(./strategy-Icons/analysis.png) no-repeat; 
  margin: 0rem 3.5rem;
  background-size:100% 100%;
}

.TikTok_head{
    /* height: 150.97px; */
    width: 100%;
    background: url(./strategy-Icons/tiktok-background.svg)no-repeat ;
    background-size:100%;
    margin-bottom: 40.47px;
}

.Creatives_head{
    /* height: 150.97px; */
    width: 100%;
    background: url(./strategy-Icons/creative-background.svg)no-repeat ;
    background-size:100%;
    margin-bottom: 40.47px;
}
.Google_head{
    /* height: 150.97px; */
    width: 100%;
    background: url(./strategy-Icons/google-background.svg)no-repeat ;
    background-size:100%;
    margin-bottom: 40.47px;
}
.Website_head{
    /* height: 150.97px; */
    width: 100%;
    background: url(./strategy-Icons/website-background.svg)no-repeat ;
    background-size:100%;
    margin-bottom: 40.47px;
}
.Priority_head{
    /* height: 150.97px; */
    width: 100%;
    background: url(./strategy-Icons/priority-background.svg)no-repeat ;
    background-size:100%;
    margin-bottom: 40.47px;
}
.list-content{
    margin-bottom: 15px;
    font-weight: 400;
font-size: 14px;
}
.card-priority{
    width: 321px;
    height: 131px;
    background: #FFFFFF;
    box-shadow: 0px 1.5398px 39px rgb(20 20 43 / 4%);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
}