  
.review12{
    display: flex;
  align-items: center;
  justify-content: center;
  height: 556px;
  max-width: 100%;
  /* border-radius: 2rem; */
  background: url(../../../assets/icons/reviewHDMAM.svg) no-repeat;
  margin: 4rem 1rem;
  background-size:100% 100%
}
.brief_head{
    height: 150.97px;
    width: 100%;
    background: url(../../../assets/images/Onboarding/document.svg) ;
    margin-bottom: 40.47px;
}
/* .strategy_head{
    height: 150.97px;
    width: 100%;
    background: url(../../../assets/images/Onboarding/strategy.svg) ;
    margin-bottom: 40.47px;
    
} */
.comm_head{
    height: 150.97px;
    width: 100%;
    background: url(../../../assets/images/Onboarding/communication.svg) ;
    margin-bottom: 40.47px;
    
}
.card{
    width: 249px;
    height: 131px;
    background: #FFFFFF;
    box-shadow: 0px 1.5398px 39px rgba(20, 20, 43, 0.04);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.pricing_card{
    margin: 27px;
    display: flex;
    flex-direction: row;
    gap: 27px;
}
.card2{
    width: 321px;
    height: 131px;
    background: #FFFFFF;
    box-shadow: 0px 1.5398px 39px rgba(20, 20, 43, 0.04);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.card3{
    margin-left: 27px;
    width: 586px;
    height: 131px;
    background: #FFFFFF;
    box-shadow: 0px 1.5398px 39px rgba(20, 20, 43, 0.04);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 40px;
}
.main_container{
    width: 431px;
    height: 285px;
    border-radius: 24px;
    box-shadow:  0px 1.5398px 39px rgba(20, 20, 43, 0.04);
    background: #FFFFFF;
}

.head{
    padding: 20px;
    background: #F5F9FF;
    border-radius: 24px 24px 0px 0px;
}

.ul {
    list-style: 'none';
}

.ul li::before{
    color: #0869FB;
}
.strategy_head{
    height: 150.97px;
    width: 100%;
    /* background: url(../../../../assets/icons/background_facebookTracking.svg)no-repeat ; */
    background: url(../../../assets/images/Onboarding/strategy.svg) no-repeat ;
    background-size:100%;
    margin-bottom: 40.47px;
}
.strategy123{
    background: url(../../../assets/icons/onboardingDocExpectationIcons/strategy-background.png)no-repeat ;
    max-width: 100%;
    background-size:100% 100%;
}

.review1{
    display: flex;
  align-items: center;
  justify-content: center;
  height: 515px;
  max-width: 100%;
  /* border-radius: 2rem; */
  background: url(../../../assets/images/review_Page.png) no-repeat;
  margin: 4rem 1rem;
  background-size:100% 100%;
}