/* .right_layout {
  overflow-y: hidden;
} */
.publish-campaign-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 700px;
  margin: auto;
  height: calc(100vh - 2rem);
}
.loader-image-container {
  width: 200px;
  height: 200px;
  background: #ffffff;
  border: 0.5px solid #e9e9e9;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.04);
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 32px;
  border-bottom-left-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
}
.publish-campaign-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
