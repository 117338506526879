.common{
    
   
    width: 1066px;
    height: 79px;
    
    /* position: absolute;
    left:24px;
    top: 24px ; */
    padding: 17px 10px 20px 24px; 
    background: #FFFFFF;
    border: 0.7699px solid #EFF0F7;
    box-shadow: 0px 1.5398px 9.2388px rgba(20, 20, 43, 0.08);
    border-radius: 10.5055px;
    margin-bottom: 23px;
}

.info{
    width: 779px;
    background: #FAFAFA;
    border-radius: 8px;
    padding: 8px;
}
.edit_button{
    background: #F8F8F8;
    border-radius: 6px;
    border: transparent;
    width: 32px;
    height: 32px;
    margin-left: 8px;
}

.text_field input{
    padding: 8px;
    font-size: 12px;
    border:'1px solid  #DDDDDD';
    
    
}

.text_field input::placeholder{
    font-style: italic;
}
.common2{
    width: 1066px;
    height: 350px;
    
    /* position: absolute;
    left:24px;
    top: 24px ; */
    padding: 24px 25px 24px 24px; 
    background: #FFFFFF;
    border: 0.7699px solid #EFF0F7;
    box-shadow: 0px 1.5398px 9.2388px rgba(20, 20, 43, 0.08);
    border-radius: 10.5055px;
    margin-bottom: 18px;
}