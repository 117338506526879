.box{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3rem;
    /* height: 100%; */
    /* overflow: ; */
}
.heading_setup{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin-bottom: 47px;
}

.text{
   width: 400px;
}
.footer_row{border-top:1px solid #DBDEE2; padding: 18px 25px}
.footer_con{display: flex; justify-content: space-between}
.back{ 
    background: none;
    border: none;
    color: #0869FB;
    
    
}
.back:hover{
    cursor: pointer;
}
.vertical{
    border-left: 1px solid #DBDEE2;
}
.form_checkbox_row{ display: flex;}