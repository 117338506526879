.banner_left{
    border-radius: 1rem;
    width: 100%;
    background-color: white;
    /* height: 35vh; */
   
    position: relative;
    max-width: 511px;
}
.hero_banner{ background-color: white; border-radius: 8px; display:flex; justify-content: space-between; align-items: center; margin-bottom: 15px; padding: 29px 29px 27px 36px;}
.banner_left span{
    color: #4A3AFF;
    font-family: 'Inter';
    font-weight: 600;
    font-size: large;

    text-transform: uppercase;
    letter-spacing: 2px;
}


.banner_left h2{
    margin: 0px 0px ;
    font-size: 35px;
    text-transform: uppercase;
    line-height: 62px;
    color: #170F49;
    font-family: 'Inter';
    font-weight: 700;
}
.banner_left p{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6F6C90;
    margin: 0;
}
.bubble_banner{
    display: flex;
    flex-direction: column;
    align-items: center;
   
    position: relative;
    border: 1px solid #E9E9E9;
    padding: 14px 20px 18px;
    border-radius: 2rem;
   
    line-height: 2.5rem;
    min-width: 487px;
    box-shadow: 0px 10.9302px 73.3835px rgba(0, 0, 0, 0.05); margin-left: 30px;
}
.bubble_banner::before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
   
    border-left: 10px solid transparent;
    border-right: 30px solid #E9E9E9;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    left: -41px;
    top: 50%;
    transform: translate(0,-50%);
}
.bubble_banner::after{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 30px solid white;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    left: -39px;
    top: 50%;
    transform: translate(0,-50%);
}
    
.bubble_banner span{
font-family: 'Inter';
font-weight: 600;
font-size: 18px;
line-height: 29px;
text-align: center;
color: #170F49;
margin: 13px 0;
}
.bubble_banner img{ display: block;}



.grid_home_page{ display: flex; justify-content: space-between;}
.grid_colum{ width: calc(50% - 8px);}
.grid_learning_column{ background: #fff; border-radius: 8px;padding: 22px 20px 5px;}
.grid_account_column{ background: #fff; border-radius: 8px; margin-bottom: 16px; padding: 22px 20px; min-height:364px;}
.grid_quick_column{ background: #fff; border-radius: 8px;padding: 22px 20px;}
.home_title{font-family: 'Inter';font-weight: 700;font-size: 18px;line-height: 20px;letter-spacing: 0.1em;text-transform: uppercase;margin: 0 0 23px;color: #4A3AFF;}
.home_social{ position: relative;}
.social_home{ padding: 12px 44px 12px 12px;background: #FFFFFF;
    box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
    border-radius: 8px; margin-bottom: 16px; display: flex; align-items: center; width: 100%; position: relative; justify-content: space-between;
    }
/* .social_home:after{ position: absolute; right: 8px; top: 0; bottom: 0; content: ''; background: url(../../../assets/icons/right_arrow.svg) no-repeat center center; width: 16px;   } */
.social_name_title{font-family: 'Inter';font-weight: 500;font-size: 14px;line-height: 17px;color: #333333; margin: 0; margin-left: 8px;}
.social_list_home{ display: flex; align-items: center;}
.social_status_tag p { margin: 0; padding: 5px 10px 6px;font-family: 'Inter';font-weight: 500;font-size: 14px;border-radius: 8px;}
.social_status_tag.warning p{color: #F8862D;background: #FEF3EA;}
.social_status_tag.success p{color: #12C8B2;background: #E7F9F7;}
.social_name_title span{color: #D6D6D6; margin: 0 5px;}
.account_connect_number{background: rgba(8, 105, 251, 0.07);font-family: 'Inter';    font-weight: 500;font-size: 10px;line-height: 20px;color: #0869FB; width: 20px; text-align: center; border-radius: 50%; margin-left: 8px;}
.home_cards{ display: flex; justify-content: space-between;}
.card_block_home{background: #FFFFFF;border: 0.216667px solid #E9E9E9;box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.03);border-radius: 6px; text-align: center; display: flex; flex-direction: column; justify-content: space-between; padding: 12px 0 0;flex-grow: 1;
    flex-basis: 0; margin: 0 5px;}
.card_block_home span{background: #E6F6FF;font-family: 'Inter';font-style: normal;font-weight: 600;font-size: 10px;line-height: 23px;text-align: center;color: #0869FB;}
.card_block_home p{ margin: 0;font-family: 'Inter';font-style: normal;font-weight: 600;font-size: 12px;line-height: 15px;color: #171717; margin: 8px 0 13px;}
.card_block_home i{ width: 17px; height: 17px; margin: auto;}
.card_block_home i img{ display: block; width: 100%; height: auto;}

.learning_home_row{ display: flex; align-items: center;background: #FFFFFF;border: 0.312785px solid #E9E9E9;box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);border-radius: 8px; padding: 20px 16px 22px; margin-bottom: 18px; transition: 0.3s ease; position: relative;}

.learning_home_row:hover{ background: #f5f5f5;}
.learning_home_img{ margin-right: 16px; min-width: 172px;width: 172px;}
.learning_home_img img{ display: block; width: 100%; height: auto;}  

.learning_item_info h4{font-family: 'Inter';font-weight: 600;font-size: 16px;line-height: 19px;color: #000000; margin: 0 0 5px 0;}
.learning_item_info p{font-family: 'Inter';font-weight: 400;font-size: 12px;line-height: 14px;color: #707070; margin: 0;}
.export_icon{ position: absolute; width: 25px; height: 25px; top: 6px; right: 6px; background: url(../../../assets/icons/common/export_icon.svg) no-repeat center center;}

.status_bar_row{ display: flex;}
.bubble_current_status{ display: flex; align-items: center; flex-direction: column;    margin-right: -50px;} 
.bubble_current_status p{ margin: 11px 0 0 0;font-family: 'Inter';font-weight: 500;font-size: 14px;line-height: 17px;text-align: center;color: #170F49;}
.bubble_current_status .icon{ width: 48px; height: 48px; background: #F3FEF3;  border-radius: 12px; margin: 0;display: flex;align-items: center;justify-content: center;}
.bubble_next_status{ display: flex; flex-direction: column; align-items: center;}

.bubble_next_action{ display: flex; align-items: center;border-radius: 12px; background:rgba(0, 102, 255 , 1);}
.bubble_next_action.disable_action{background:rgba(0, 102, 255 , 0.3);}
.bubble_next_action a{ color: #fff;  text-decoration: none; padding:4px 15px;}
.bubble_next_action.disable_action a{opacity: 0.9;}
.bubble_next_action > span{background: #F2F7FF; width: 44px; height: 48px; margin: 0;border-radius: 10.9302px; display: flex;align-items: center;justify-content: center;}
.bubble_next_status p { margin: 11px 0 0 0;font-family: 'Inter';font-weight: 500;font-size: 14px;line-height: 17px;text-align: center;color: #170F49;}
.separator_status{ width: 137px; height: 8px; margin-top: 20px; background: url(../../../assets/icons/separator.svg) no-repeat right center; margin: 20px 10px 0;}
.bubble_status_info{font-family: 'Inter';font-weight: 400;font-size: 14px;line-height: 17px;text-align: center;color: #6F6C90; margin-top: 16px; margin-bottom: 0;}
.status_bubble_inner{ padding: 10px 20px;}
.bubble_next_action .started_button{padding: 14px 24px;border-radius: 12px; }
.error .bubble_next_action > span{background: #FFEBEB;}

@media screen and (max-width : 1451px){
    .status_bubble_inner{}
    .separator_status{ width: 100px;}
}
@media screen and (max-width : 1380px){
    .card_block_home span{ font-size: 9px;}
    .grid_quick_column{padding: 22px 10px;}
    .grid_account_column{padding: 22px 10px;}
    .grid_learning_column{padding: 22px 10px;}
    .card_block_home p{ font-size: 11px;}
    .social_name_title{ font-size: 12px;}
    .social_name_title span{ margin: 0;}
    .social_home{    padding: 12px 30px 12px 12px;}
}

.facebook_strategy_banner{
    height: 171.97px;
    width: 100%;
    background: url(./strategy-Icons/facebook-background.svg)no-repeat ;
    background-size:100%;
    margin-bottom: 40.47px;
}