.ad-group-title {
  padding: 0.75rem 0 0.75rem 1rem;
  border-bottom: 1px solid #f0f0f0;
}
.ad-group-container {
  background: #ffffff;
  border: 2px solid #e6f6ff;
  border-radius: 8px;
}
.ad-group-search-lists {
  margin-top: 1rem;
  height: 45vh;
  overflow: auto;
}
.ad-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
  border-radius: 8px;
  width: 95%;
  padding: 8px 15px;
  margin: 0 auto 1rem auto;
}
.reach {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.add-keyword-container {
  display: flex;
  justify-content: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 0;
  cursor: pointer;
}
.select-box div[role='button'] {
  padding: 0 6px 0 6px !important;
}
