.ad-single-copy-container {
  width: 226px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 1rem;
  padding-right: 1.1875rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.ad-copy-spends {
  display: flex;
  gap: 16px;
  padding: 0 1rem;
  justify-content: center;
}
.divider,
.divider-creative,
.divider-ad-copies {
  position: absolute;
  top: 1rem;
  right: -2rem;
  transform: rotate(90deg);
  width: 24px;
  height: 0;
  border: 0.642384px solid #e2e2e2;
}
.divider-ad-copies {
  width: 26.14px;
  left: 1.75rem;
}
.divider-creative {
  width: 37px;
  left: 2rem;
}
.ctr-container {
  position: relative;
  padding: 3.8543px 11.5629px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.64px;
}
.cpr-container {
  position: relative;
  padding: 3.8543px 11.5629px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.64px;
}
.spends-container {
  padding: 3.8543px 11.5629px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.64px;
}
.creative-container {
  width: 302px;
  /* height: 320px; */
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}
.creative-image-container,
.ads-image-container {
  width: 302px;
  height: 320px;
}
.creative-image-container img,
.ads-image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.ads-image-container img {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.creative-spends {
  width: 302px;
  display: flex;
  padding: 0px;
  gap: 2.5rem;
  justify-content: center;
  padding: 12.5px 0;
}
.ctr-creative-container,
.cpa-creative-container,
.cpm-creative-container,
.cvr-creative-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
}
.ads-description-container {
  display: flex;
  background-color: #f0f2f5;
  padding: 8px 9px;
  align-items: center;
  justify-content: space-between;
}
.ads-description-button {
  background: #e4e6ea;
  border-radius: 4px;
  width: 74px;
  height: 25px;
  padding: 5px 7px;
  cursor: pointer;
}
