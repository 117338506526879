.pmax-campaign-age-range {
  display: flex;
  align-items: center;
  gap: 12px;
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiSelect-select:focus {
    background-color: transparent !important;
  }
  .separator {
    width: 11.14px;
    height: 0;
    border-bottom: 1.92886px solid #d3d3d3;
  }
  .MuiTypography-root {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12.5376px;
    line-height: 19px;
    color: #333333;
    margin-left: -4px;
  }
}
