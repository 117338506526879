.pmax-g-campaigns-list {
  font-size: 16px;
  
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5em;
    padding: 1em 0;
  }

  &__title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5625em;

    text-align: center;
    text-transform: uppercase;
    color: #170F49;
    margin: 0;
  }

  &__description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.625em;
    text-align: center;
    color: #6F6C90;
    margin: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.4em;
    margin-left: 1.2em;
    margin-right: 1.2em;
  }

  &__footer {
    padding: 1.4em 0;
  }

  &__button--width-full {
    background: #EBF3FF;
    box-shadow: 0em 0.1413em 0.6121em rgba(242, 242, 242, 0.6);
    border-radius: 0.375em;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1em;
    width: 100%;

    color: #0869FB;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    border: 0;
    padding: 0.75em;
    cursor: pointer;
  }
}
