
.paper_container{display: flex; flex-direction: column; gap: 18; overflow: auto ; max-height: 100%; }

.pr_heading{font-family: 'Inter';font-size: 18px; font-weight: 700;line-height: 20px;letter-spacing: 0.1rem; color: #4A3AFF; text-align: center; text-transform: uppercase;margin: 0; }
.layout{padding: 27px 41px 25px}
.header2{font-family: 'Inter'; font-weight: 700; color:#170F49; font-size: 30px;line-height: 62px;text-align: center; margin: 0;text-transform: uppercase;}
.top_head{margin-bottom: 36px;
    
    padding: 2rem;
}
.parent{
    height: 50vh; overflow: auto;
}
.accordian{border-radius: 10px;  padding-left: 20px; padding-right: 20px; padding-bottom: 2rem; box-shadow: none; }
.accor_head{font-weight: 600;}
.tag{color: #666666; font-family: sans-serif; font-size: small; font-weight: lighter; }
.content{
    border: 1px solid #ECECEC;
    border-radius: 8px;
    
    padding: 10px;
    box-shadow: 0px 11px 34px rgba(0, 0, 0, 0.03);
    align-items: flex-start;
}
.content a{
    color: #619BF3; text-decoration: none;
}
.wrap{
   
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.back{ 
    background: none;
    border: none;
    color: #0869FB;
    
    
}
.back:hover{
    cursor: pointer;
}
.footer_row{border-top:1px solid #DBDEE2; padding: 18px 25px; position: absolute; bottom: 0;width: 100%;}
.footer_con{display: flex; justify-content: space-between}

.btn {
    border: none;
    background: url(../../../assets/icons/back_arrow.svg);
    background-size: cover;
    width: 40px;
    height: 40px;
}

.btn:hover{
    cursor: pointer;
}
.edit{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 40px;
    z-index: 1;
}
.edit_response{
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    align-items: center;
    gap:10px;
    border: 2px solid #0869FB;
    border-radius: 12px;
    font-family: 'Inter';
    text-align: center;
    color: #0869FB;
    
    background: none;
}

.edit_response:hover{
    cursor: pointer;
}
.resp{
    display: flex;
    flex-direction: column;
    position: relative;
}