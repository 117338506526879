.abtests-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f9ff;
  padding: 24px 30px 28px 24px;
  .adsets-logo-image-container {
    width: 64px;
    height: 64px;
    background: #ffffff;
    box-shadow: 0px 14.9731px 19.9642px rgba(27, 33, 40, 0.08);
    border-radius: 11.9785px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.abtests-tests-types {
  display: flex;
  gap: 100px;
  padding: 20px 20px 30px 36px;
  .messaging-text-tests {
    flex-basis: 30%;
    ul {
      padding-left: 16px;
      margin-top: 10px;
    }
    ul li {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.4;
    }
    ul .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
  .form-tests {
    flex-basis: 30%;
    ul {
      padding-left: 16px;
      margin-top: 10px;
    }
    ul li {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.4;
    }
    ul .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
  .additional-tests {
    flex-basis: 40%;
    ul {
      padding-left: 16px;
      margin-top: 10px;
    }
    ul li {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.4;
    }
    ul .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
}
.css-6hp17o-MuiList-root-MuiMenu-list > .abtest-type-value {
  display: flex;
  flex-direction: column;
}
.MuiSelect-select:focus {
  background-color: white !important;
}
