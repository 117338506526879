.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
}
.steps {
  
  padding: 3rem 0;
  max-height: 590px;
  overflow: auto ;
  
}
.steps_inner{margin: auto;display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-width: 263px !important;}
.stepHelper {
  width: 100%;
  position: relative;
}
.step {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  /* font-family: ; */
}
.step-name {
  margin-bottom: 0;
  font-size: 16px;
  color: #adadad;
  /* margin-top: 0; */
}
.step-status {
  margin-top: 0;
  font-size: 12px;
  color: #aeaeae;
  /* margin-bottom: 0; */
}
.step-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.question-logo {
  background: #f9f9f9;
  border-radius: 12px;
  min-width: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar_layout{ position: relative; display: flex; height: 100%; flex-direction: column; padding-bottom: 94px; }
.nav-footer-icons {
  display: flex;
  justify-content: center;
  gap: 18px;
  padding: 1.5rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);;
}
.navigation_footer{ position: absolute; bottom: 0; left: 0; right: 0; background: #fff;}
.footer-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  border-radius: 8px;
  width: 45px;
  height: 45px;
}
.footer-icon img {
  width: 20px;
  height: 20px;
}
.arrow-img {
  width: 10px !important;
  height: 10px !important;
}
.profile-icon {
  display: flex;
  align-items: center;
  gap: 3px;
  /* padding: 0 0.35rem; */
}
.moreInfoIcon-img {
  width: 28px !important;
  height: 28px !important;
}
/* .selected {
  filter: invert(35%) sepia(45%) saturate(7364%) hue-rotate(210deg)
    brightness(100%) contrast(105%);
} */


.stepHighlight{
  background: #F1F7FF;
  /* width: 264px; */
  border-radius: 10px;
  /* padding: 10px; */
}
 