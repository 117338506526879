.brand-awareness-form {
  margin-top: 18px;
  display: flex;
  gap: 25px;
  #brand {
    .form-field {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 14px;
      label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #585969;
        width: 88px;
      }
      .MuiInput-underline:before {
        border-bottom: none !important;
      }
      .MuiInput-underline:after {
        border-bottom: none !important;
      }
    }
  }
  .interest-keywords {
    .selected-keyword-tag-container {
      position: relative;
      width: 262px;
      height: 170px;
      background: #ffffff;
      border: 1px solid #eaeaec;
      border-radius: 6px;
      margin-top: 12px;
    }
    .selected-keyword-tag-container {
      .selected-keywords {
        min-height: 96px;
        max-height: 125px;
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        overflow-y: scroll;
      }
      .input-keywords {
        position: absolute;
        bottom: 0;
        z-index: 1;
      }
      .MuiInput-underline:before {
        border-bottom: none !important;
      }
      .MuiInput-underline:after {
        border-bottom: none !important;
      }
    }
  }
}
#target,
#advertise {
  .form-field {
    display: flex;
    flex-direction: column;
    gap: 8px;
    // align-items: center;
    margin-bottom: 14px;
    label {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #585969;
      // width: 88px;
    }
    .MuiInput-underline:before {
      border-bottom: none !important;
    }
    .MuiInput-underline:after {
      border-bottom: none !important;
    }
  }
}
.interest-keyword-form {
  margin-top: 18px;
  // display: flex;
  // gap: 25px;
  .interest-keywords {
    .selected-keyword-tag-container {
      position: relative;
      width: 100%;
      height: 170px;
      background: #ffffff;
      border: 1px solid #eaeaec;
      border-radius: 6px;
      margin-top: 12px;
    }
    .selected-keyword-tag-container {
      .selected-keywords {
        min-height: 96px;
        max-height: 125px;
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        overflow-y: scroll;
      }
      .input-keywords {
        position: absolute;
        bottom: 0;
        z-index: 1;
        width: 100%;
      }
      .MuiInput-underline:before {
        border-bottom: none !important;
      }
      .MuiInput-underline:after {
        border-bottom: none !important;
      }
    }
  }
}
.audience-targetting-form {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaec;
  border-radius: 6px;
  height: 128px;
  margin-top: 18px;
  padding: 16px 0 0 16px;
  .MuiInput-underline:before {
    border-bottom: 1px solid #cecece !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
}
