.questionNavIconStyle {
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #fdfdfd;
  box-shadow: 0px 4px 4px rgba(174, 174, 174, 0.25);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  border-radius: 50%;
}
.question-nav-container {
  display: flex;
  gap: 1.8rem;
  position: fixed;
  bottom: 1.5rem;
  right: 2rem;
  z-index: 2;
  align-items: center;
}
.question-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  max-width: 700px;
  margin: auto;
  /* margin-top: 8rem; */
}

.slide_question {
  position: relative;
  /* transition: background-position 0.3s ease; */
  min-height: calc(100vh - 97px);
}
.slide_question:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff url(../../assets/icons/background-2.png) no-repeat center top;
  background-size: 150% 100%;
  transition: 0.4s ease;
  z-index: 0;
  background-attachment: fixed;
}
.question-odd.animate:before {
  background-size: 150% 100%;
  background-position: left top;
}
.question-even.animate:before {
  background-size: 100% 100%;
}
