.common3{
    
   
    width: 1069px;
    /* height: 71px; */
    
    /* position: absolute;
    left:24px;
    top: 24px ; */
    /* padding: 24px 10px 24px 24px;  */
    background: #FFFFFF;
    border: 0.7699px solid #EFF0F7;
    box-shadow: 0px 1.5398px 9.2388px rgba(20, 20, 43, 0.08);
    border-radius: 10.5055px;
    margin-bottom: 27.5px;
}