/* client and AM Start */
.share-box table {
    width: 100%;
    text-align: left;
    border: 1px solid #F1F0F0;
    border-radius: 8px;
    margin-top: 15px;
}

.text-center {
    text-align: center;
}

.share-box table thead {
    padding: 0 10px;
}
.share-box table tbody{
    padding: 0 10px;
}
.share-box table thead th {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 40px;
    text-transform: uppercase;
    border-bottom: 1px solid #F1F0F0;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 10px;
}

.share-box table tbody td {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 40px;
    align-items: center;
    padding: 0 10px;

}

.tbl-delete img {
    margin: 0 auto;
    display: block;
}

.ac-id {
    color: #000000;
}

.shared-on {
    color: rgba(0, 0, 0, 0.75);
}

.status-verifying {
    color: #F8862D
}
.status-unavailable{
    color: #E84B47;
}
.status-read-access{
    color: #1ABFA1;
}
.status-complete-access{
    color: #1ABFA1;
}
.share-box h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
}

.more-inst {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 62px;
    text-align: center;
    display: block;
    color: #0869FB;
    text-decoration: none;
}

.more-inst img {
    margin-left: 5px;
}

.field-box img {
    margin-left: 15px;
}

.field-box input {
    height: 7px;
}

.field-box {
    display: flex;
}

.share-box .tabs-button.active-tab {
    color: #0869FB;
    border-bottom: 1px solid #0869FB;
    opacity: 1;
}
.share-box .tabs-button {
    background: #FFFFFF;
    border-bottom: 1px solid #DFDFDF;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    width: 50%;
    height: 33px;
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.5;
}

.share-contents ul {
    padding: 0 15px;
}

.share-contents ul li, .share-contents ul li a{
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.5);
}

.share-box {
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
    border-radius: 8px;
    margin-bottom: 16px;
    /* display: flex; */
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: calc(50% - 30px);
    justify-content: space-between;
    margin: 0 auto;
}

.read-share {
    display: flex;
    margin-bottom: 50px;
    padding: 0 20px;
}
/* client and AM End */