.hero123 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 515px;
  max-width: 100%;
  /* border-radius: 2rem; */
  background: url(../../../../assets/icons/expectationHDMAM.svg) no-repeat;
  background-position: center center;
  margin: 4rem 1rem;
  background-size: 100% 100%;
}
.scroll_items {
  margin: 4rem 1rem;
}

.communication_title,
.what_we_need_title,
.facebook_title,
.bonus_title,
.footer-bar {
  margin-bottom: 30px;
}

.commmunication_head {
  height: 150.97px;
  width: 100%;
  background: url(../../../../assets/icons/background_commuicationAndReporting.svg)
    no-repeat;
  background-size: 100%;
  margin-bottom: 40.47px;
}
.what_we_need_head {
  height: 150.97px;
  width: 100%;
  background: url(../../../../assets/icons/background_whatWeNeedFromYou.svg)
    no-repeat;
  background-size: 100%;
  margin-bottom: 40.47px;
}
.facebook_head {
  height: 150.97px;
  width: 100%;
  background: url(../../../../assets/icons/background_facebookTracking.svg)
    no-repeat;
  background-size: 100%;
  margin-bottom: 40.47px;
}
.bonus_head {
  height: 150.97px;
  width: 100%;
  background: url(../../../../assets/icons/background_bonus.svg) no-repeat;
  background-size: 100%;
  margin-bottom: 40.47px;
}
.nextSteps_head {
  height: 150.97px;
  width: 100%;
  background: url(../../../../assets/icons/backgrond_nextSteps.svg) no-repeat;
  background-size: 100%;
  margin-bottom: 40.47px;
}

.list {
  display: flex;
  align-items: center;
  border-radius: 200px;
  height: 72px;
  width: 326px;
  padding: 10px;
  background-color: #f5f9ff;
  margin-left: 24px;
  margin-bottom: 66px;
}

.circle {
  width: 56px;
  margin-right: 16px;
  line-height: 56px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  background-color: #0869fb;
  border: 1px solid transparent;
}

.main_grid {
  display: flex;
  justify-content: space-between;
}

.grid_col {
  width: calc(50% - 8px);
  padding: 0;
  background: #ffffff;
  margin: 16px;
  box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}
.grid_acc_col {
  background: #fff;
  border-radius: 8px;
  padding: 0 !important;
  min-height: 364px;
  width: 100%;
}
.social_list {
  display: flex;
  align-items: center;
  padding: 20px;
}
.css-1ex1afd-MuiTableCell-root {
  color: #333333;
}
.ul-custom {
  list-style: none;
}
.ul-custom li::marker {
  background-color: #0869fb;
}
.ul-custom::before {
  content: '\2022';
  color: #0869fb;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1.2em;
}

.strategy12 {
  background: url(../../../../assets/icons/onboardingDocExpectationIcons/strategy-background.png)
    no-repeat;
  max-width: 100%;
  background-size: 100% 100%;
}

.back_button {
  justify-content: start;
  margin-left: 20px;
}
