.right_main_section {
  display: block;
}
/* .right_layout {
  overflow-y: hidden;
} */
.campaign-container {
  padding: 1rem 1rem 0 1rem;
  height: 90vh;
  position: relative;
}
.service-campaign-container {
  height: 95vh;
}
.competitor-campaign-container {
  height: 95vh;
}
.campaign-header-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.campaign-title {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  /* line-height: 19px; */
}
.campaign-info {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8f8f8f;
}
.ad-section-container {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}
/* .ad-group-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  } */
.ad-group-section {
  flex-basis: 42%;
  height: 74vh;
}
.ad-level-section {
  background: #ffffff;
  border: 2px solid #e6f6ff;
  border-radius: 8px;
  flex-grow: 1;
  height: 74vh;
}

.hdm-campaign-creation-container {
  height: calc(100vh - 1.6rem);
  overflow: auto;
}
/* .campaign-types-container {
    height: 95vh;
  } */
.hdm-campaign-creation-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
}
.hdm-campaign-creation-header__title {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 25px !important;
  /* text-align: center; */
  text-transform: uppercase !important;
  color: #170f49 !important;
  line-height: 48px !important;
}
.hdm-campaign-creation-header__description {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  text-align: center !important;
  color: #6f6c90 !important;
}
.hdm-campaign-sub-container {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 2.26013px 9.7939px rgba(242, 242, 242, 0.6);
  border-radius: 6.80095px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
  width: 100%;
  padding: 0.75rem;
  /* position: relative; */
}
.hdm-indicator {
  width: 20px;
  background-color: #eaf2ff;
  border-top-left-radius: 6.80095px;
  border-bottom-left-radius: 6.80095px;
}

.hdm-campaign-creation-field-header__title {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #333333 !important;
  border-bottom: 0.609177px solid #f0f0f0;
  padding-bottom: 0.8rem;
  padding-top: 0.5rem;
}
.hdm-campaign-form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  /* justify-content: space-evenly; */
  margin: 1.25rem 0;
}
.hdm-campaign-form-container .hdm-campaign-form-element {
  /* width: 192px; */
  /* height: 58px; */
  background: #ffffff;
  box-shadow: 0px 0px 31.3379px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.hdm-campaign-creation-field-header {
  position: relative;
}
/* .button-container {
    position: absolute;
  } */
.hdm-detailButton {
  /* width: 111px; */
  height: 32px;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #0869fb !important;
  background: #eaf2ff !important;
  border-radius: 3px !important;
  border: 0 !important;
  text-transform: none !important;
  position: absolute !important;
  right: 8px;
  top: -2px;
}
.hdm-form-input-label {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: #6f767e !important;
}
.hdm-form-input-element {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.icon-container {
  width: 46px;
  height: 46px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(23, 15, 73, 0.04);
  border-radius: 9.04506px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.read-icon-container,
.write-icon-container {
  width: 40px;
  height: 40px;
  border: 1px solid #cccccc;
  border-radius: 8px 0px 0px 8px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.write-icon-container {
  border-radius: 0px 8px 8px 0px;
  border-left: 0;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #999999;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 400;
}
.hdm-switch-disabled {
  opacity: 0.4;
}
