.upload-image-field {
  font-size: 14px;
  --height: 21.875em;
  height: var(--height);

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    padding: 3em 0;
  }

  &__label {
    font-size: inherit;
    width: calc(100% - 7em);
    height: calc(var(--height) - 6em);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
    border-radius: 0.1934em;
    border: 0.0984em dashed #83b4fd;
    border-spacing: 1px;
    background: #f5f9ff;
    height: 100%;
  }

  &__image-container {
    width: 100%;
    height: 21.875em;

    display: flex;
    // align-items: center;
    // justify-content: center;

    & img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__text {
    color: #333;
    font-family: Inter;
    font-size: 0.7em;
    font-style: normal;
    font-weight: 600;
    line-height: 0.8703em;
  }

  &__upload-button {
    margin-top: 1em !important;
    font-size: 0.8em !important;
  }
}
