.right_main_section {
  display: block;
}
.audit-pointers-check-container,
.video-link-check-container {
  display: flex;
  gap: 2.5px;
  align-items: center;
}
.video-link {
  margin-left: 38px;
  margin-top: 5px;
}
.video-link .MuiInput-underline:before {
  border-bottom: none !important;
}
.video-link .MuiInput-underline:after {
  border-bottom: none !important;
}
