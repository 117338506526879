.segment-card {
  font-size: 14px;
  background: #FFFFFF;
  border: 0.0625em solid #F0F0F0;
  box-shadow: 0em 0.1413em 0.6121em rgba(242, 242, 242, 0.6);
  border-radius: 0.375em;
  display: flex;
  width: 100%;
  height: 200px;

  button {
    cursor: pointer;
  }

  &__indicator {
    width: 1.25em;
    height: 200px;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    flex-shrink: 0;
    
    &[data-border-indicator="complete"] {
      background-color: rgba(26, 191, 161, 0.1);
    }
    
    &[data-border-indicator="editing"] {
      background-color: #EAF2FF;
    }
  }

  &__content {
    padding: 0.875em 0.75em;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 0.75em 0;
    border-bottom: 0.0381em solid #F0F0F0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-left,
  &__header-right {
    display: flex;
    align-items: center;
    gap: 0.625em;
  }

  &__header-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875em;
    line-height: 1.0625em;
    color: #333333;
  }

  &__header-adsets-count {
    color: #6D6E7C;
  }
  
  &__delete-button {
    width: 2rem;
    height: 2rem;
    background: #FDEEEE;
    border-radius: 0.1875em;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  &__adset-level-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.375em 1em;
    gap: 0.625em;

    height: 2rem;

    background: #EAF2FF;
    border-radius: 0.1875em;
    border: none;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.75em;
    line-height: 1.25em;
    color: #0869FB;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding-top: 0.75em;
  }

  &__form-row {
    display: flex;
    gap: 1.5em;
    align-items: center;
  }

  &__field {
    display: flex;
    align-items: center;
    gap: 0.5em;
    border: none;
    padding: 0;

    input {
      height: 0.5em;
      background: #FFFFFF;
      border: 0.0625em solid #EAEAEC;
      border-radius: 0.375em;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 0.875em;
      line-height: 1.25em;
    }

    .MuiFormHelperText-root.Mui-error {
      font-size: 0.75em;
    }
  }

  &__field-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 1.5em;
    color: #585969;
    width: fit-content;
  }

  &__field--width-full {
    flex-basis: 100%;
  }

  &__budget-container {
    display: flex;
    padding: 1em;
    background: #FAFAFA;
    border-radius: 0.5em;
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: auto;
    justify-content: flex-end;
    padding: 0.75em 0;
  }

  &__submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.875em 1.5em;
    gap: 0.625em;
    background: #0869FB;
    border-radius: 0.375em;
    color: #FFFFFF;
    border: none;
  }

  &__tag {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    gap: 8px;
    height: 2rem;

    background: #F8F8F8;
    border-radius: 3px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75em;
    line-height: 1.25em;
    color: #2E3044;
  }
}

.date-field {
  &__button {
    margin: 0;
    padding: 0;
    border: transparent;
    background-color: transparent;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1em;
    color: #0869FB;
  }

  &__button--primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5em 1em;
    gap: 0.5em;
    background: #0869FB;
    border-radius: 0.375em;
    color: #FFFFFF;
  }
}
