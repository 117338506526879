.google-campaign-selector-container {
  .google-campaign-selector__cards {
    display: flex;
    justify-content: center;
    gap: 71px;
    .google-campaign-selector__card {
      &:hover {
        border: 2px solid #0869fb;
        box-shadow: 0px 25px 30px rgba(8, 105, 251, 0.08);
        border-radius: 12px;
      }
      width: 250px;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #f2f2f2;
      box-shadow: 0px 25px 30px rgba(80, 80, 159, 0.06);
      border-radius: 12px;
      padding-top: 20.94px;
      .google-campaign-selector__header {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .google-campaign-selector__image-view {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 35px 0;
      }
      .unlock-button,
      .generating-data-button,
      .completed-button,
      .ongoing-button {
        display: flex;
        gap: 15px;
        width: 177px;
        height: 53px;
        padding: 6px 13px;
        background: #ebf3ff;
        border-radius: 12px;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-bottom: 32px;
        cursor: pointer;
        .lock-icon-image-wrapper {
          width: 36.82px;
          height: 36.82px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          border-radius: 10.2279px;
        }
      }
      .generating-data-button {
        background: #fef5eb;
      }
      .completed-button {
        background: #e8f9f6;
        gap: 36px;
      }
      .ongoing-button {
        background: #ebf3ff;
        gap: 58px;
      }
    }
  }
}
