.create-cs-modal {
  &__content {
    display: flex;
    gap: 24px;
  }

  &__radio-group {
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    gap: 24px;
  }

  &__card {
    flex-shrink: 0;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 25px 30px rgba(80, 80, 159, 0.06);
    border-radius: 12px;
    padding: 23px 12px;
    width: 215px;

    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }

  &__card-title {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: flex-start;
  }

  &__radio {
    padding: 0 !important;
  }
}