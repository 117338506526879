.campaign-preview-container {
  max-width: 682px;
  margin: auto;
  margin-top: 2.25rem;
}
.campaign-preview-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.campaign-preview-type-container {
  border: 1px solid #f0f0f0;
  box-shadow: 0px 2.26013px 9.7939px rgba(242, 242, 242, 0.6);
  border-radius: 6.80095px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  padding-right: 1.5rem;
  padding-left: 1.25rem;
  position: relative;
}
.campaign-link-container {
  position: absolute;
  right: 1.05rem;
  top: 1.25rem;
  cursor: pointer;
}
.campaign-preview-header__title {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 25px !important;
  line-height: 62px !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  text-transform: uppercase !important;
  color: #170f49;
}
.campaign-preview-type-field-header__title {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #333333;
  padding-top: 1.25rem;
}
.campaign-preview-header__description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #6f6c90;
}
.indicator {
  width: 20px;
  background-color: #1abfa11a;
  border-top-left-radius: 6.80095px;
  border-bottom-left-radius: 6.80095px;
}
.campaign-form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  /* justify-content: space-around; */
  margin: 1.25rem 0;
}
.campaign-form-container .campaign-form-element {
  width: 192px;
  /* height: 134px; */
  background: #ffffff;
  box-shadow: 0px 0px 31.3379px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.form-input-label {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: #6f767e !important;
}
.form-input-element {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.icon-container {
  width: 46px;
  height: 46px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(23, 15, 73, 0.04);
  border-radius: 9.04506px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.campaign-preview-value {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: #0869fb;
}
