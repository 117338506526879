.calendar-popover .MuiPaper-root {
  width: 352px;
  padding: 20px 16px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  box-shadow: 0px 10px 24px rgba(35, 40, 60, 0.05);
  border-radius: 8px;
}
/* .rmdp-wrapper {
  width: 320px;
}
.rmdp-top-class,
.rmdp-day-picker {
  display: block !important;
} */
.rmdp-left {
  background: url(./arrow-left-svg.svg);
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
}
.rmdp-right {
  background: url(./arrow-right-svg.svg);
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
}
.rmdp-left:hover {
  background-color: transparent;
  box-shadow: none;
}
.rmdp-right:hover {
  background-color: transparent;
  box-shadow: none;
}
.start-date {
  width: 154px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 10px 12px 10px 16px;
}
.start-date-container,
.end-date-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.time-zone-view {
  margin-top: 12px;
  background: #f8f9fd;
  border-radius: 6px;
  padding: 7px 0 6px 15px;
}
.rmdp-left i {
  display: none;
}
.rmdp-right i {
  display: none;
}
/* .rmdp-header {
  padding-top: 0px;
  margin-top: 0px;
}
.rmdp-header-values {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
.rmdp-week-day {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #abacb4;
}
.rmdp-day {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2e3044;
}
.rmdp-range {
  color: #ffffff;
}
.rmdp-deactive {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #c0c1c7;
}
.rmdp-border {
  border: none;
} */
.rmdp-border {
  border: none;
}
.rmdp-wrapper {
  border-radius: 0.5rem;
  width: min-content !important;
  &.rmdp-shadow {
    box-shadow: none;
  }
  .rmdp-range:nth-child(1) {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  .rmdp-range:last-child {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  .rmdp-calendar {
    padding: 0px;

    .rmdp-header {
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin: 0px;
    }
    .rmdp-day-picker {
      padding: 0px;
    }
    .rmdp-header-values {
      * {
        font-family: Lato !important;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: center;
      }
    }

    .rmdp-week {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      .rmdp-today {
        .sd {
          background-color: white;
          border: 1px solid #ebf3ff;
        }
      }
      .rmdp-week-day {
        width: 2rem;
        height: 2rem;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #abacb4;
      }
      .rmdp-day {
        width: 2.88rem;
        height: 2.5rem;
        position: relative;

        .sd {
          position: absolute;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #2e3044;
          top: 0.4rem;
          left: 0.5rem;
          height: 1.8rem;
          width: 1.8rem;
        }
        &.rmdp-deactive {
          .sd {
            color: #c0c1c7;
          }
        }
      }
      .rmdp-day:hover {
        .sd {
          background: #0869fb;
          color: white !important;
          border-radius: 50%;
        }
      }
      .rmdp-day-hidden:hover {
        background: white;
        color: white !important;
        border-radius: 50%;
        .sd {
          background: #0869fb;
          color: white !important;
        }
      }
      .rmdp-range {
        box-shadow: none;
        background: white;
        background: #ebf3ff;
        &.rmdp-deactive {
          .sd {
            color: #2e3044;
          }
        }
        &.start {
          .sd {
            background: #0869fb;
            border-radius: 50%;
            color: white;
          }
        }
        &.end {
          .sd {
            background: #0869fb;
            border-radius: 50%;
            color: white;
          }
        }
      }
      .rmdp-range-hover {
        background: #ebf3ff;
        box-shadow: none;
        &.start {
          .sd {
            background: #0869fb;
            border-radius: 50%;
            color: white;
          }
        }
        &.end {
          sd {
            background: #0869fb;
            border-radius: 50%;
            color: white;
          }
        }
      }
    }
  }
}